    import React, { useState, useEffect } from 'react';
    import { Link, useNavigate, useLocation } from 'react-router-dom';
    import { Input, message, Button } from 'antd';
    import supabase from '../supabaseClient';



    function PasswordReset() {
      const navigate = useNavigate();
      const [password, setPassword] = useState('');
      const [passwordConfirmation, setPasswordConfirmation] = useState('');
    
      useEffect(() => {
        supabase.auth.onAuthStateChange(async (event, session) => {
          if (event === 'PASSWORD_RECOVERY') {
            // This part is handled in the onSubmit function now
          }
        });
      }, [navigate]);
    
      const onSubmit = async (e) => {
        e.preventDefault();
        
        if (password !== passwordConfirmation) {
          return message.error('Passwords do not match.');
        }
    
        try {
          const { data, error } = await supabase.auth.updateUser({ password: passwordConfirmation });
          if (error) {
            message.error('An error occurred: ' + error.message);
          } else {
            message.success('Password has been updated. Happy days!');
            navigate('/');
          }
        } catch (error) {
          message.error('An error occurred: ' + error.message);
        }
      };
    

      return (
      <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          maxWidth: '500px',
          width: '100%',
          padding: '24px',
        }}
      >
      

        <div className="signup" style={{width: '100%', maxWidth: 500}}>
          <div style={{ display: 'flex', gap: 4, flexDirection: 'column', alignItems: 'flex-start', marginBottom: 40 }}>
          <img src="/magicform_white.png" className="logo" alt="Logo" />
            <h2>Reset your password</h2>
            <p className="sub">All you have to do is make sure both passwords match, we'll take care of the rest.</p>
          </div>
          </div>


          <form onSubmit={onSubmit}>
          <div style={{display: 'flex', gap: 4, flexDirection: 'column', marginBottom: 16 }}>
            <h4>New password</h4>
            <Input.Password
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div style={{display: 'flex', gap: 4, flexDirection: 'column', marginBottom: 16 }}>
            <h4>Confirm password</h4>
            <Input.Password
              placeholder="Confirm your new password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </div>
          
          
          <Button style={{width: '100%'}} type="primary" htmlType="submit">
            Update Password
          </Button>
        </form>
      
      </div>
    </div>
      );
    }

    export default PasswordReset;
