import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useParams,  NavLink, Link, useNavigate } from 'react-router-dom'; 
import { Modal, Input, Button, Drawer, Tooltip, Popconfirm, Space, message, Menu, Form, Dropdown, Checkbox, Select } from 'antd';
import supabase from '../supabaseClient';
import Navbar from '../pages/Navbar';
import { QuestionCircleOutlined, SettingOutlined, CopyOutlined } from '@ant-design/icons';
import { parseISO, format } from 'date-fns';
import LoadingComponent from '../components/LoadingComponent';
import { UserContext } from '../UserContext';
import { LicenseContext } from "../LicenseContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Include the Quill CSS

const { Option } = Select;


function FormSettings() {
  const [form, setForm] = useState(null);
  const { checkFormAccess } = useContext(UserContext);
  const { formId } = useParams();
  const [hasAccess, setHasAccess] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [selectionType, setSelectionType] = useState('checkbox');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sendEmailOnSubmit, setSendEmailOnSubmit] = useState(false);
   const [emailInput, setEmailInput] = useState('');
  const [otherRecipients, setOtherRecipients] = useState([]);
  const [sendEmailToSubscriber, setSendEmailToSubscriber] = useState(false);
  const [emailTitle, setEmailTitle] = useState("");
  const [replyToAddress, setReplyToAddress] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const { TextArea } = Input;
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { licenseStatus } = useContext(LicenseContext);
 



  const confirmationMessage = (
    <div>
      <strong>Are you sure?</strong> <br />
    This will save the current data<br />
      and send a test email to your email.
    </div>
  );



  useEffect(() => {
    const verifyAccess = async () => {
      const access = await checkFormAccess(formId);
      setHasAccess(access);
      if (!access) {
        // Redirect if no access
      navigate('/');
      }
    };

    verifyAccess();
  }, [formId, checkFormAccess, navigate]);


  const emailsToArray = (emails) => {
    if (!emails) return [];
  return emails.split(',').map(email => email.trim());
}
  FormSettings.modules = {
    toolbar: [
    ['bold', 'underline'], // Add bold and underline
    [{'list': 'ordered'}, {'list': 'bullet'}], // Add list and bullet
    ['link'] // Add link
    ],
  };

  FormSettings.formats = [
  'bold', 'underline', // Allow bold and underline formatting
  'list', 'bullet', // Allow list and bullet formatting
  'link' // Allow links
  ];


  const fetchCurrentSettings = async () => {
    try {
      const { data, error } = await supabase
      .from('forms')
      .select('send_email_subscriber, email_subscriber_title, reply_to_address, email_subscriber_body')
      .eq('form_id', formId)
        .single();

      if (error) throw error;

      return data; // data contains the settings
    } catch (error) {
    console.error('Error fetching current settings:', error);
    message.error('Error fetching settings');
      return null; // Return null in case of an error
    }
  };


  const showDrawer = async () => {
    const settings = await fetchCurrentSettings();
    if (settings) {
      setSendEmailToSubscriber(settings.send_email_subscriber || false);
      setEmailTitle(settings.email_subscriber_title || "");
      setReplyToAddress(settings.reply_to_address || "");
      setEmailContent(settings.email_subscriber_body || "");
    }

    setIsDrawerVisible(true);
  };


  const handleDrawerClose = () => {
    setIsDrawerVisible(false); // Replacing handleCancel
  };
  const handleDrawerSubmit = async () => {
    setConfirmLoading(true);


    try {
      const { error } = await supabase
      .from('forms')
        .update({
          send_email_subscriber: sendEmailToSubscriber,
          email_subscriber_title: emailTitle,
          reply_to_address: replyToAddress,
        email_subscriber_body: emailContent
        })
      .eq('form_id', formId);

      if (error) throw error;

    message.success('Settings updated successfully');
    } catch (error) {
    console.error('Error updating settings:', error);
    message.error('Error updating settings');
    } finally {
      setIsDrawerVisible(false);
      setConfirmLoading(false);
    }
  };


  const handleSendEmailOnSubmitChange = async (e) => {
    const checked = e.target.checked;
    setSendEmailOnSubmit(checked);
    await updateSendEmailOnSubmitSetting(checked);
  };

  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  }

  const handleEmailSubmit = async () => {
    if(emailInput) {
      const newEmails = [...otherRecipients, emailInput];
      await updateOtherRecipientsSetting(newEmails);
      setEmailInput('');
    }
  }

  const handleEmailRemove = async (emailToRemove) => {
    const newEmails = otherRecipients.filter(email => email !== emailToRemove);
    await updateOtherRecipientsSetting(newEmails);
  }


  const updateSendEmailOnSubmitSetting = async (sendEmailOnSubmit) => {
    try {
      const { data, error } = await supabase.from('forms').update({
          send_email_on_submit: sendEmailOnSubmit
        }).eq('form_id', formId);

      if (error) {
        throw error;
      }
    } catch (error) {
      console.error('Error updating send email on submit setting:', error);
      message.error('Failed to update send email on submit setting');
    }
  };


  const updateOtherRecipientsSetting = async (emails) => {
    try {
      // emails is already an array, no need to join
      const { data, error } = await supabase.from('forms').update({
          other_recipients: emails
        }).eq('form_id', formId);

      if (error) {
        throw error;
      }
      setOtherRecipients(emails);
      message.success('Other recipients updated successfully');
    } catch (error) {
      console.error('Error updating other recipients:', error);
      message.error('Failed to update other recipients');
    }
  };



  const handleSendTestEmail = async () => {
    // Update the settings first
    try {
      const updateResponse = await supabase
        .from('forms')
        .update({
          send_email_subscriber: sendEmailToSubscriber,
          email_subscriber_title: emailTitle,
          reply_to_address: replyToAddress,
          email_subscriber_body: emailContent
        })
        .eq('form_id', formId);

      if (updateResponse.error) throw updateResponse.error;
      console.log('test')
      // Once settings are updated, send the test email
      const testEmailResponse = await fetch(`https://magicform.onrender.com/api/send-test-email/${formId}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
    });

      if (!testEmailResponse.ok) throw new Error('Failed to send test email.');

      message.success('Test email sent successfully');
    } catch (error) {
      console.error('Error:', error);
      message.error('Error: ' + error.message);
    }
  };






  const isActive = (path) => {
    return window.location.pathname === path;
  };


  const deleteForm = async () => {
    try {
      const { error } = await supabase.from('forms').delete().eq('form_id', formId);
      if (error) {
        throw error;
      }
      message.success('Form deleted successfully');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error deleting form:', error);
      message.error('Failed to delete form');
    } finally {
      setDeleteLoading(false);
    }
  };


 

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(formId)
      .then(() => {
        message.success('Form ID copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy Form ID to clipboard');
      });
  }, [formId]);

  const handleTitleEdit = () => {
    setNewTitle(form.name);
    setEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    try {
      await supabase.from('forms').update({ name: newTitle }).eq('form_id', formId);
      setForm((prevForm) => ({ ...prevForm, name: newTitle }));
      setEditingTitle(false);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating form title:', error);
      message.error('Failed to update form title');
    }
  };

  useEffect(() => {
    const fetchForm = async () => {
      const { data, error } = await supabase.from('forms').select('*').eq('form_id', formId).single();
      if (error) {
        console.error('Error fetching form:', error);
      } else {
        setForm(data);
        setOtherRecipients(data.other_recipients || []);
        setSendEmailOnSubmit(data.send_email_on_submit || false);
      }

      const { data: submissionsData, error: submissionsError } = await supabase
        .from('submissions')
        .select('*')
        .eq('form_id', formId)
        .order('created_at', { ascending: false });
      if (submissionsError) {
        console.error('Error fetching submissions:', submissionsError);
      } else {
        setSubmissions(submissionsData);
      }
    };

    fetchForm();
  }, [formId]);




  const menu = (
    <Menu>
      <Menu.Item key="edit-title"  onClick={() => {
          setNewTitle(form?.name); // Set newTitle to the current form's name
          setIsModalVisible(true); // Then, show the modal
        }}>
        Edit Form Name
      </Menu.Item>
      <Menu.Item key="delete-form">
        <Popconfirm
          placement="topLeft"
          title="Delete Form"
          description="Are you sure? After deleting the form all data will be gone forever."
          onConfirm={deleteForm}
          okText="Yes"
          cancelText="No"
        >
          Delete Form
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  if (!form) {
    return <div><LoadingComponent /></div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
      <Navbar />
      <div style={{width: '100%', maxWidth: 1200, userSelect: 'none', paddingTop: 100, display: 'flex', flexDirection: 'row', justifyContent:'flex-start', gap: 8,  alignItems: 'center', }}>
        <p className="backToForms" onClick={() => navigate('/')}>Forms</p> <p className='sub' style={{paddingTop: 4}}> ﹥ </p> <p style={{fontWeight:'bold'}}>{form.name}</p>
      </div>
      <div style={{width: '100%', maxWidth: 1200, paddingTop: 24}}>
        

      <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Modal
            title="Edit Title"
            visible={isModalVisible}
            onOk={handleTitleSave} // Assuming handleTitleSave not only saves but also hides the modal
            onCancel={() => setIsModalVisible(false)}
            okText="Save"
            cancelText="Cancel"
          >
            <Input type="text" value={newTitle} onChange={handleTitleChange} />
          </Modal>

        

        
        </div>

        <div style={{borderBottom: '1px solid #2A2A2C', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 16, paddingBottom: 8}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 16}}>
            <NavLink to={`/forms/${formId}`} className={isActive(`/forms/${formId}`) ? "navItem activeNavItem" : "navItem" }>
              <p>Submissions</p>
            </NavLink>

            <NavLink to={`/forms/${formId}/settings`} className={isActive(`/forms/${formId}/settings`) ? "navItem activeNavItem" : "navItem" }>
              <p>Notifications</p>
            </NavLink>

            <NavLink to={`/forms/${formId}/integrations`}
              className={
                isActive(`/forms/${formId}/integrations`)
                  ? "navItem activeNavItem"
                  : "navItem"
              }
            >
              <p >Integrations
              </p>
            </NavLink>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 8}}>
            <Dropdown overlay={menu}>

<Button><SettingOutlined /> Edit form</Button>
            </Dropdown>
          <Button  icon={<CopyOutlined />} onClick={copyToClipboard}>Copy Form ID</Button>
          
          </div>
        </div>




      </div>

      <Drawer
        
        className='drawer'
        title="Send email to subscriber"
        open={isDrawerVisible}
        onClose={handleDrawerClose}
        footer={
    <div className='colDir' style={{backgroundColor: '#111', gap: 8, padding: '12px 0px' }}>
            <Button onClick={handleDrawerClose}>Cancel</Button>
          <Button type="primary" onClick={handleDrawerSubmit}>Submit</Button>
          
          </div>
        }
      >
        
       <div className='colDir' style={{gap: 24}}>
          
          

       <div className='colDir' style={{gap: 4}}>
            <div className='rowDir' style={{justifyContent: 'space-between', gap: 8}}>
              <div className="rowDir" style={{gap: 8}}>
                <p className='small' style={{color: '#f9f9f9'}}>Send confirmation email to subscriber</p>
                <Tooltip title="This will sent a confirmation mail to the email that is submitted in the form data.">
                  <QuestionCircleOutlined/>
                </Tooltip>
              </div>
              <Checkbox checked={sendEmailToSubscriber} style={{ transform: 'scale(1)' }} onChange={(e) => setSendEmailToSubscriber(e.target.checked)}></Checkbox>
            </div>
          </div>

          <div className='colDir' style={{gap: 4, opacity: !sendEmailToSubscriber ? 0.2 : 1}}>
            <h5>Reply to address<span className="required"> *</span></h5>
            <Input
              type="email"
              placeholder="Inser the email you want replies to be send to"
              value={replyToAddress}
              onChange={(e) => setReplyToAddress(e.target.value)}
              disabled={!sendEmailToSubscriber}
            />
          </div>

          
          <div className='colDir' style={{gap: 4, opacity: !sendEmailToSubscriber ? 0.2 : 1}}>
            <h5>Subject line email<span className="required"> *</span></h5>
            <Input
              type="text"
              placeholder="Thank you for subscribing"
              value={emailTitle}
              onChange={(e) => setEmailTitle(e.target.value)}
              disabled={!sendEmailToSubscriber}
            />
          </div>

          


          <div className='colDir' style={{gap: 4, opacity: !sendEmailToSubscriber ? 0.2 : 1}}>
            <ReactQuill
              value={emailContent}
              onChange={setEmailContent}
              modules={FormSettings.modules}
              formats={FormSettings.formats}
              readOnly={!sendEmailToSubscriber}
              style={!sendEmailToSubscriber ? {pointerEvents: 'none', opacity: 0.2} : {}} // Add pointerEvents: 'none' to prevent text selection
            />
          </div>

          <Popconfirm
            title={confirmationMessage}
            onConfirm={handleSendTestEmail}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{opacity: !sendEmailToSubscriber ? 0 : 1, pointerEvents: !sendEmailToSubscriber ? 'none' : 'auto'}}>Send Test Email</Button>
          </Popconfirm>


        </div>


        
      </Drawer>



      <div className="settings">

        <Form>
          <div className="row">
            <div>
              <p className="bold">
                Receive email on submission{" "}
                {licenseStatus === null && (
                  <Link to="/pricing" ><span className="paidPlanBadge">Upgrade plan</span></Link>
                )}
              </p>
              <p className="sub">
                Send an email to yourself when a new submission arrives.
              </p>
            </div>
            <Checkbox
              checked={sendEmailOnSubmit}
              onChange={handleSendEmailOnSubmitChange}
              disabled={licenseStatus === null}
            />
          </div>

          <div className="row">
            <div>
              <p className="bold paidPlanBadgeContainer">
                Add Email to notification List{" "}
                {licenseStatus === null && (
                  <Link to="/pricing" ><span className="paidPlanBadge">Upgrade plan</span></Link>
                )}
              </p>

              <p className="sub">
                Send an email to other addresses on a new submission.
              </p>
              <div className="rowDir" style={{flexWrap: 'wrap', gap: 2}}>
              {otherRecipients.map((email) => (
                <div className="recipients" key={email}>
                  <span className="sub">{email}</span>
                  <div onClick={() => handleEmailRemove(email)}><span className="remove">x</span></div>
                </div>
              ))}
              </div>
            </div>
            <div>
              <Space.Compact>
                <Input
                  type="email"
                  style={{ width: 240, marginRight: 8 }}
                  placeholder="Enter email"
                  value={emailInput}
                  onChange={handleEmailInputChange}
                  disabled={licenseStatus === null}
                />
                <Button
                  disabled={licenseStatus === null}
                  onClick={handleEmailSubmit}
                >
                  Add Email
                </Button>
              </Space.Compact>
            </div>
          </div>
          <div className="row">
            <div>
              <p className="bold paidPlanBadgeContainer">
                Send email to subscriber{" "}
                {licenseStatus === null && (
                  <Link to="/pricing" ><span className="paidPlanBadge">Upgrade plan</span></Link>
                )}
              </p>
              <p className="sub">
                Send a success email to the subscriber when a new submission
                arrives.
              </p>
            </div>
            <Button disabled={licenseStatus === null} onClick={showDrawer}>
              Settings
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default FormSettings;
