import React, { useContext, useState, useEffect } from 'react';
import { Link, useLocation, useParams, NavLink, useNavigate } from 'react-router-dom'; 
import { UserContext } from '../UserContext';
import supabase from '../supabaseClient';
import { Input, message, Drawer, Button, Dropdown, Tooltip, Menu, MenuProps } from 'antd';
import { mixpanelTrack } from '../mixpanelConfig'; 
import { LicenseContext } from '../LicenseContext';

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { submissionCount } = useContext(LicenseContext);
  const [email, setEmail] = useState('');
    const {user, firstName: initialFirstName, lastName: initialLastName } = useContext(UserContext);
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [userId, setUserId] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
const [newPassword, setNewPassword] = useState('');
const [confirmNewPassword, setConfirmNewPassword] = useState('');
const [passwordError, setPasswordError] = useState('');



  



useEffect(() => {
  const sessionSubscription = supabase.auth.onAuthStateChange((event, session) => {
    setUserId(session?.user?.id);
  });

  // Cleanup function
  return () => {
    if (sessionSubscription?.unsubscribe) {
      sessionSubscription.unsubscribe();
    } else if (sessionSubscription?.remove) {
      sessionSubscription.remove();
    }
  };
}, []);

useEffect(() => {
  if (isDrawerVisible && user) {
    
    const fetchUserData = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('first_name, last_name')
        .eq('id', user.id) // Assuming `user.id` is available from your context
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
        message.error('Failed to fetch user details.');
      } else {
        // Prefill the inputs with fetched data
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setEmail(user.email); 
      }
    };

    fetchUserData();
  }
}, [isDrawerVisible, user, initialFirstName, initialLastName]);


 async function handleLogout() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Error signing out:', error);
        alert(error.message);
      }
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Error signing out');
    }
  }


  async function handleEmail(e) {
    e.preventDefault();

    try {
      
const { data, error } = await supabase.auth.updateUser({email: email});
      console.log('Response:', user, error);

      if (error) {
        throw error;
      }

      console.log('Success! Email updated:', user);
    } catch (error) {
      console.error('Error setting up:', error);
      alert(error.error_description || error.message);
    }
  }

  async function handleEmailUpdate(e) {
    e.preventDefault();
    
    try {
      const { error } = await supabase.auth.updateUser({
        email: email // Ensure this email state is updated with the input value
      });
    
      if (error) throw error;
    
      message.success('Email updated successfully');
      setIsEditingEmail(false); // Exit email editing mode
    } catch (error) {
      console.error('Error updating email:', error.message);
      message.error('Failed to update email: ' + error.message);
    }
    
  }


  const handleChangePassword = async () => {
    // Check if the new password and confirmation match
    if (newPassword !== confirmNewPassword) {
      setPasswordError("The new passwords do not match.");
      return;
    }
  
    // Proceed to update the password
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword // Update the password directly for the authenticated user
      });
  
      // Check for any errors returned by Supabase
      if (error) throw error;
  
      // If successful, clear the form and provide user feedback
      message.success('Your password has been updated successfully.');
      setIsEditingPassword(false); // Exit editing mode
      // Clear the state
      setNewPassword('');
      setConfirmNewPassword('');
      setPasswordError(''); // Clear any previous error messages
    } catch (error) {
      console.error('Error updating password:', error.message);
      message.error(`Failed to update password: ${error.message}`);
      setPasswordError(error.message); // Display the error message
    }
  };
  



  

  async function handleNameUpdate(e) {
    e.preventDefault();
       let errors = {
    firstName: firstName.length < 2,
    lastName: lastName.length < 2
  };

  // Check if there are any errors
  if (errors.firstName || errors.lastName) {
    // If there are, set the error states and return
    setFirstNameError(errors.firstName);
    setLastNameError(errors.lastName);
    
       if (errors.firstName && errors.lastName) {
      console.error("Both first name and last name should be at least 2 characters.");
      message.warning("Both first name and last name should be at least 2 characters.");
    } else {
      if (errors.firstName) {
        console.error("First name should be at least 2 characters.");
        message.warning("First name should be at least 2 characters.");
      }
      if (errors.lastName) {
        console.error("Last name should be at least 2 characters.");
        message.warning("Last name should be at least 2 characters.");
      }
    }
    
    return;
  }

    try {
      const { error } = await supabase
        .from('users')
        .update({ first_name: firstName, last_name: lastName })
        .eq('id', userId);

      if (error) {
        throw error;
      }

      console.log('Success! Names updated.');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error updating names:', error);
      alert(error.error_description || error.message);
    }
  }

  


  async function handleLogout() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Error signing out:', error);
        alert(error.message);
      }
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Error signing out');
    }
  }
  
  
const isActive = (path) => {
  return window.location.pathname === path;
};

const navigateToPricing = () => {
  mixpanelTrack('View Plans Clicked');
  navigate('/pricing');
};

const navigateToProfile = () => {
  navigate('/profile');
};

  const items: MenuProps['items'] = [
  {
    key: 'view-plan',
    label: 'View plans',
  },
  {
    key: 'profile',
    label: 'View profile',
  },
  {
    key: 'sign-out',
    label: 'Sign Out',
  },
];

const menu = (
  <Menu>
    <Menu.Item key="view-plan" onClick={navigateToPricing}>
      View plans
    </Menu.Item>

    <Menu.Item key="profile"  onClick={() => {
  // Update the state right before showing the drawer
  setFirstName(initialFirstName || '');
  setLastName(initialLastName || '');
  setIsDrawerVisible(true)}}>
      View profile
    </Menu.Item>

    <Menu.Item key="sign-out" onClick={handleLogout}>
      Sign Out
    </Menu.Item>
  </Menu>
);

const { formId } = useParams();
const countTooltip = <span>Amount of submissions collected</span>;
function bothActive(value) {
  // match both root and /forms/formid routes
  return location.pathname === '/' || location.pathname === `/forms/${formId}` || location.pathname === `/forms/${formId}/settings` || location.pathname === `/forms/${formId}/integrations`;
}

  function Avatar({ firstName, lastName }) {
  const getInitials = () => {
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '';
    return `${firstInitial}${lastInitial}`;
  };

  return (
    <div className="avatar">
      <p>{getInitials()}</p>
    </div>
  );
}

  return (
    <div className="navbar" style={{width: '100%', position: 'fixed', border: '1px solid #2a2a2c', backgroundColor: '#111', zIndex: 6, display: 'flex', justifyContent: 'center'}}>
      <div  style={{width: '100%', maxWidth: '1200px'}}>
     
     
     
     
      <Drawer
  title="Update Profile"
  placement="right"
  onClose={() => {
    setIsDrawerVisible(false);
    setIsEditingName(false);
    setIsEditingEmail(false); 
  }}
  
  visible={isDrawerVisible}
  width={320}
>

<div style={{borderBottom: "1px solid #2A2A2C", paddingTop: 24, paddingBottom: 24}} >
{!isEditingName ? (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <div>
      <h4 style={{paddingBottom: 8}}>Name</h4>
      <p className='sub'>{firstName} {lastName}</p>
      
      
      </div>
      <Button  onClick={() => setIsEditingName(true)}>
        Edit
      </Button>
      </div>
    </div> ) : (
  <form onSubmit={handleNameUpdate}>
    <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
      <h4>First name</h4>
      <Input
  placeholder="Enter your first name..."
  value={firstName}
  onChange={(e) => setFirstName(e.target.value)}
  status={firstNameError ? "error" : ""}
/>
    </div>
    <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
      <h4>Last name</h4>
      <Input
  placeholder="Enter your last name..."
  value={lastName}
  onChange={(e) => setLastName(e.target.value)}
  status={lastNameError ? "error" : ""}
/>
    </div>
    <Button  type="primary" htmlType="submit"  onClick={() => setIsEditingName(false)}>
      Update name
    </Button>
    <Button
          style={{ marginLeft: 8 }}
          onClick={() => setIsEditingName(false)}
        >Cancel</Button>
  </form>
  )
    }
    </div>



<div style={{borderBottom: "1px solid #2A2A2C", paddingTop: 24, paddingBottom: 24}} >
    {!isEditingEmail ? (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
        <div>
        <h4 style={{paddingBottom: 8}}>Email</h4>
      <p className='sub'>{email}</p>
        </div>
        <Button onClick={() => setIsEditingEmail(true)}>Edit</Button>
      </div>
    ) : (
      <form onSubmit={handleEmailUpdate} style={{ paddingBottom: 24 }}>
        <div style={{ marginBottom: 16 }}>
        <h4 style={{paddingBottom: 8}}>Email</h4>
          <Input
            placeholder="Enter your new email..."
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <Button type="primary"  htmlType="submit">
          Update Email
        </Button>
        <Button
          style={{ marginLeft: 8 }}
          onClick={() => setIsEditingEmail(false)}
        >
          Cancel
        </Button>
      </form>
    )}
  </div>

  <div style={{  paddingTop: 24, paddingBottom: 24}} >
  {!isEditingPassword ? (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 24 }}>
      <div>
        <h4 style={{paddingBottom: 8}}>Password</h4>
      <p className='sub'>Change your password</p>
      </div>
  <Button onClick={() => setIsEditingPassword(true)}>Edit</Button>
  </div>
) : (
  <div>
    <h4 style={{paddingBottom: 8}}>New password</h4>
    <Input.Password
      placeholder="New Password"
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      style={{ marginBottom: 16 }}
    />

<h4 style={{paddingBottom: 8}}>Confirm new password</h4>
    <Input.Password
      placeholder="Confirm New Password"
      value={confirmNewPassword}
      onChange={(e) => setConfirmNewPassword(e.target.value)}
      style={{ marginBottom: 16 }}
    />
    {passwordError && <div style={{ color: 'red', marginBottom: 16 }}>{passwordError}</div>}
    <Button
      type="primary"
      onClick={handleChangePassword}
      style={{ marginRight: 8 }}
    >
      Update Password
    </Button>
    <Button
      onClick={() => {
        setIsEditingPassword(false);
        setNewPassword('');
        setConfirmNewPassword('');
        setPasswordError('');
      }}
    >
      Cancel
    </Button>
  </div>
)}

  </div>
</Drawer>




      {user && (
        <div style={{height: '72px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0px 0px'}}>
            
              <Link style={{}} to="/"><img src="/magicform_white.png" className="logo" alt="Logo" /></Link>
            
            
            
            <div className='rowDir' style={{gap: 8}}>
            <Tooltip placement="bottomRight" title={countTooltip}>
              <div className='submissionCount rowDir'>
                <img src="/submission_count.svg"  />
                <p>{submissionCount}</p>
              </div>
              </Tooltip>
              
                <Dropdown overlay={menu} placement="bottomRight">
                <div className='avatar'>              
                <img src="/user.png" className="user"  />
                  </div>
                </Dropdown>
              </div>
        </div>
      
      ) 
        }
    </div>
    </div>
  );
}

export default Navbar;
