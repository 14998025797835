import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { useParams, NavLink, useNavigate } from 'react-router-dom'; 
import { Table, Select, Input, Tooltip, Button, Modal, Popconfirm, message, Menu, Dropdown, Checkbox } from 'antd';
import supabase from '../supabaseClient';
import Navbar from '../pages/Navbar';
import { CloudDownloadOutlined, DeleteOutlined, CopyOutlined, SettingOutlined} from '@ant-design/icons';
import { LicenseContext } from '../LicenseContext';
import { UserContext } from '../UserContext';
import LoadingComponent from '../components/LoadingComponent';
import { saveAs } from 'file-saver';

function FormDetail() {
  const [form, setForm] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(false);
const [hasMore, setHasMore] = useState(true);
  const [tableColumns, setTableColumns] = useState([]);
  const { checkFormAccess } = useContext(UserContext);
  const { formId } = useParams();
  const [hasAccess, setHasAccess] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [selectionType, setSelectionType] = useState('checkbox');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [secondColumnKey, setSecondColumnKey] = useState(null);
  const [hoveredCell, setHoveredCell] = useState(null);
  const { licenseStatus, productName, formsCount } = useContext(LicenseContext);
  const [bottom, setBottom] = useState('bottomRight');
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPageSelected, setIsPageSelected] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10, // Adjust based on your default page size
  });
  const { Option } = Select;

  const codeSnippets = [
    {label: "Contact form", snippet: 'https://framer.com/m/Template-Contact-form-QkH6.js@1b0Z2LQmXwAcepTD99uQ', previewUrl: 'https://getmagicform.com/templates/contact-form'},
    {label: "File upload", snippet: 'https://framer.com/m/Template-File-upload-sLxO.js@M5ROj0EdHoTHjNxpHcQ5', previewUrl: 'https://getmagicform.com/templates/file-upload'},
    {label: "Lead generation", snippet: 'https://framer.com/m/Template-Lead-generation-ygY4.js@VKowDP7g4G9XsjC5wTp0', previewUrl: 'https://getmagicform.com/templates/lead-generation'},
    {label: "Typeform style", snippet: 'https://framer.com/m/Template-Typeform-style-lgSL.js@azT2Thzc1Nhw5s2O4JVJ', previewUrl: 'https://getmagicform.com/templates/typeform-style'},
    {label: "Event registration", snippet: 'https://framer.com/m/Template-event-registration-gBl9.js@kVkP5CxAO83wfwbuPHEB', previewUrl: 'https://getmagicform.com/templates/event-registration'},
    {label: "Email signup", snippet: 'https://framer.com/m/Template-Email-signup-nBWV.js@wziizF5Lr37iaaVqhRm2', previewUrl: 'https://getmagicform.com/templates/email-signup'},
    {label: "NPS score", snippet: 'https://framer.com/m/Template-NPS-Nsbg.js@qN0dkNitgrQPkdsheRf4', previewUrl: 'https://getmagicform.com/templates/nps'},
  ];

  const [selectedSnippet, setSelectedSnippet] = useState(codeSnippets[0].snippet);
  const [previewUrl, setPreviewUrl] = useState(codeSnippets[0].previewUrl);

  
  const fetchAllSubmissions = async () => {
    setLoading(true);
    const allSubmissions = [];
    let from = 0;
    const batchSize = 1000;
    
    while (true) {
      const { data, error } = await supabase
        .from('submissions')
        .select('*')
        .eq('form_id', formId)
        .order('created_at', { ascending: false })
        .range(from, from + batchSize - 1);
      
      if (error) {
        console.error('Error fetching submissions:', error);
        message.error('Failed to fetch submissions');
        break;
      }
      
      if (data.length === 0) {
        break;
      }
      
      allSubmissions.push(...data);
      from += batchSize;
    }
    
    setSubmissions(allSubmissions);
    setLoading(false);
  };
  
  useEffect(() => {
    fetchAllSubmissions();
  }, [formId]);
  
  
  
  const copyComponentToClipboard = useCallback(() => {
    navigator.clipboard.writeText(selectedSnippet)
      .then(() => message.success('Code snippet copied to clipboard'))
      .catch(err => {
        console.error("Error copying to clipboard: ", err);
        message.error('Failed to copy code snippet to clipboard');
      });
  }, [selectedSnippet]);

  const handleFileDownload = async (file) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SUPABASE_STORAGE_URL}/${file.file_url}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      saveAs(blob, file.file_name); // file.file_name should be the name you want to save as
      message.success('Download is completed. Please check your download folder');
    } catch (error) {
      console.error('Error downloading file:', error);
      message.error('Failed to download file');
    }
  };

  const isActive = (path) => {
    return window.location.pathname === path;
  };

  const deleteForm = async () => {
    try {
      const { error } = await supabase.from('forms').delete().eq('form_id', formId);
      if (error) {
        throw error;
      }
      message.success('Form deleted successfully');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error deleting form:', error);
      message.error('Failed to delete form');
    } finally {
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    const verifyAccess = async () => {
      const access = await checkFormAccess(formId);
      setHasAccess(access);
      if (!access) {
       navigate('/');
      }
    };
    verifyAccess();
  }, [formId, checkFormAccess, navigate]);

  const exportSelectedRowsCsv = () => {
    const canDownloadFiles = productName?.includes("+ Files");
    const urlPrefix = 'https://sjcnjqhwjjzrccsmofmz.supabase.co/storage/v1/object/public/magicform_files/';
    const selectedSubmissions = selectedRowKeys.map(key => submissions[key]);

    const allHeaders = new Set();
    selectedSubmissions.forEach(submission => {
      Object.keys(submission.data).forEach(key => {
        if (!key.startsWith('url') && !key.startsWith('utm_') && !['_gcl_aw', '_ga', '_fbp', '_hstc'].includes(key)) {
          allHeaders.add(key);
        }
      });
    });

    if (canDownloadFiles) {
      const maxFiles = selectedSubmissions.reduce((max, submission) => Math.max(max, submission.files ? submission.files.length : 0), 0);
      for (let i = 0; i < maxFiles; i++) {
        allHeaders.add(`file_url_${i + 1}`);
      }
    }

    const headers = Array.from(allHeaders);
    const csvRows = [headers.join(',')];

    selectedSubmissions.forEach(submission => {
      const values = headers.map(header => {
        let value = '';
        if (header.startsWith('file_url_')) {
          if (canDownloadFiles) {
            const fileIndex = parseInt(header.split('_')[2]) - 1;
            if (submission.files && submission.files[fileIndex]) {
              value = urlPrefix + submission.files[fileIndex].file_url;
            }
          }
        } else {
          value = submission.data[header] || '';
        }
        const escaped = ('' + value).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'selected_submissions.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    message.success('File download is completed. Please check your download folder');
  };

  const exportSelectedRowsJson = () => {
    const canDownloadFiles = productName?.includes("+ Files");
    const urlPrefix = 'https://sjcnjqhwjjzrccsmofmz.supabase.co/storage/v1/object/public/magicform_files/';
    const selectedSubmissions = selectedRowKeys.map(key => submissions[key]);

    const modifiedSubmissions = selectedSubmissions.map(submission => {
      const modifiedSubmission = {};

      Object.keys(submission.data).forEach(key => {
        if (productName?.includes("Analytics") || (!key.startsWith('url') && !key.startsWith('utm_') && !['_gcl_aw', '_ga', '_fbp', '_hstc'].includes(key))) {
          modifiedSubmission[key] = submission.data[key];
        }
      });

      if (canDownloadFiles && submission.files) {
        submission.files.forEach((file, index) => {
          modifiedSubmission[`file_url_${index + 1}`] = urlPrefix + file.file_url;
        });
      }

      return modifiedSubmission;
    });

    const jsonStr = JSON.stringify(modifiedSubmissions, null, 2);
    const blob = new Blob([jsonStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'selected_submissions.json');
    link.click();
    URL.revokeObjectURL(url);
    message.success('File download is completed. Please check your download folder');
  };

  const confirmDelete = async () => {
    setDeleteLoading(true);
    const idsToDelete = selectedRowKeys.map(key => submissions[key]?.id).filter(Boolean);

    try {
      const { error: filesError } = await supabase
        .from('files')
        .delete()
        .in('submission_id', idsToDelete);

      if (filesError) {
        throw filesError;
      }

      const { error: submissionsError } = await supabase
        .from('submissions')
        .delete()
        .in('id', idsToDelete);

      if (submissionsError) {
        throw submissionsError;
      }

      setSubmissions(prevSubmissions => prevSubmissions.filter(submission => !idsToDelete.includes(submission.id)));
      setSelectedRowKeys([]);
      message.success('Submissions and related files deleted successfully');
    } catch (error) {
      console.error('Error deleting rows:', error);
      message.error('Failed to delete submissions and related files');
    } finally {
      setDeleteLoading(false);
    }
  };

  const cancelDelete = () => {
    message.info('Cancelled delete operation');
  };


  

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      const startIdx = (pagination.current - 1) * pagination.pageSize;
      const endIdx = pagination.current * pagination.pageSize;
      const pageKeys = submissions.slice(startIdx, endIdx).map((_, index) => startIdx + index);
      setIsPageSelected(pageKeys.every(key => newSelectedRowKeys.includes(key)));
    },
    onSelect: (record, selected) => {
      const startIdx = (pagination.current - 1) * pagination.pageSize;
      const endIdx = pagination.current * pagination.pageSize;
      const pageKeys = submissions.slice(startIdx, endIdx).map((_, index) => startIdx + index);
      setIsPageSelected(pageKeys.every(key => selectedRowKeys.includes(key)));
    },
    onSelectAll: (selected) => {
      const startIdx = (pagination.current - 1) * pagination.pageSize;
      const endIdx = pagination.current * pagination.pageSize;
      const pageKeys = submissions.slice(startIdx, endIdx).map((_, index) => startIdx + index);
  
      if (selected) {
        setSelectedRowKeys([...new Set([...selectedRowKeys, ...pageKeys])]);
        setIsPageSelected(true);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(key => !pageKeys.includes(key)));
        setIsPageSelected(false);
      }
    },
    columnTitle: (
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              onClick={() => {
                const startIdx = (pagination.current - 1) * pagination.pageSize;
                const endIdx = pagination.current * pagination.pageSize;
                setSelectedRowKeys([...new Set([...selectedRowKeys, ...submissions.slice(startIdx, endIdx).map((_, index) => startIdx + index)])]);
                setIsPageSelected(true);
              }}
            >
              Select Page ({Math.min(pagination.pageSize, submissions.length - (pagination.current - 1) * pagination.pageSize)})
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setSelectedRowKeys(submissions.map((_, index) => index));
                setIsPageSelected(false);
              }}
            >
              Select All ({submissions.length})
            </Menu.Item>
            {selectedRowKeys.length > 0 && (
              <Menu.Item
                onClick={() => {
                  setSelectedRowKeys([]);
                  setIsPageSelected(false);
                }}
              >
                Deselect All
              </Menu.Item>
            )}
          </Menu>
        }
      >
        <Checkbox checked={isPageSelected} />
      </Dropdown>
    )
  };
  
  
  
  
  
  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    const startIdx = (newPagination.current - 1) * newPagination.pageSize;
    const endIdx = newPagination.current * newPagination.pageSize;
    const pageKeys = submissions.slice(startIdx, endIdx).map((_, index) => startIdx + index);
    setIsPageSelected(pageKeys.every(key => selectedRowKeys.includes(key)));
  };
  
  
    

  

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(formId)
      .then(() => {
        message.success('Form ID copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy Form ID to clipboard');
      });
  }, [formId]);

  const copyValueToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        message.success('Value copied to clipboard');
      })
      .catch(() => {
        message.error('Failed to copy value to clipboard');
      });
  };

  const handleTitleEdit = () => {
    setNewTitle(form.name);
    setEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    try {
      await supabase.from('forms').update({ name: newTitle }).eq('form_id', formId);
      setForm((prevForm) => ({ ...prevForm, name: newTitle }));
      setEditingTitle(false);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating form title:', error);
      message.error('Failed to update form title');
    }
  };

  useEffect(() => {
    const toastMessage = localStorage.getItem('toastMessage');
    if (toastMessage) {
      message.success(toastMessage);
      localStorage.removeItem('toastMessage');
    }
  
    const fetchFormAndSubmissions = async () => {
      try {
        const { data: formData, error: formError } = await supabase
          .from('forms')
          .select('*')
          .eq('form_id', formId)
          .single();
        
        if (formError) throw formError;
        if (!formData) {
          message.error('No form data found');
          return;
        }
        setForm(formData);
  
        const { data: submissionsData, error: submissionsError } = await supabase
          .from('submissions')
          .select(`
            *,
            files:files!left(file_url)
          `)
          .eq('form_id', formId)
          .order('created_at', { ascending: false });
  
        if (submissionsError) throw submissionsError;
  
        if (!submissionsData || submissionsData.length === 0) {
          setSubmissions([]);
          return;
        }
  
        const standardizedData = submissionsData.map(submission => {
          const lowerCaseData = {};
          Object.keys(submission.data).forEach(key => {
            lowerCaseData[key.toLowerCase()] = submission.data[key];
          });
          return { ...submission, data: lowerCaseData };
        });
  
        standardizedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  
        let emailKey = Object.keys(standardizedData[0].data).find(key => key === 'email');
  
        setSubmissions(standardizedData);
  
        let columns = [
          {
            title: 'Email',
            dataIndex: ['data', 'email'],
            key: 'email',
            className: 'email-column',
            render: (email, record) => (
              <div>
                <span>{email}</span>
              </div>
            ),
          },
          {
            title: 'Other data',
            className: 'other-column',
            render: (text, record) => {
              const count = Object.keys(record.data).filter(key => key !== 'email').length;
              return count > 0 ? count : 0;
            },
            key: 'extraValuesCount'
          },
          {
            title: 'Files',
            key: 'downloadFiles',
            render: (text, record) => {
              const canDownloadFiles = productName?.includes("+ Files");
              return (
                <div style={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                  {record.files && record.files.map((file, index) => (
                    canDownloadFiles ? (
                      <div className='download' key={index} onClick={() => handleFileDownload(file)}>
                        <CloudDownloadOutlined style={{ cursor: 'pointer' }} />
                      </div>
                    ) : (
                      <Tooltip key={index} title={<span>Before you can download your files, you must first add the Files add-on. <a href="/pricing" style={{ textDecoration: "underline" }}>Go to pricing</a></span>}>
                        <div className='download'>
                          <CloudDownloadOutlined style={{ cursor: 'not-allowed', color: '#ccc' }} />
                        </div>
                      </Tooltip>
                    )
                  ))}
                </div>
              );
            },
          },
          {
            title: 'Timestamp',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'other-column',
            render: (text) => {
              const date = new Date(text);
              const minutes = date.getMinutes().toString().padStart(2, '0');
              return (
                <p className="small">
                  {date.getDate()} {date.toLocaleString('default', { month: 'long' })}{' '}
                  {date.getFullYear().toString().slice(-2)} on {date.getHours()}:{minutes}
                </p>
              );
            },
            defaultSortOrder: 'descend', // This line sets the default sort order to descending
            
          }
          
         
        ].filter(column => !!column);
  
        setTableColumns(columns);
  
      } catch (error) {
        console.error('Error fetching form and submissions:', error);
        message.error('Failed to fetch form and submissions');
      }
    };
  
    fetchFormAndSubmissions();
  }, [formId]);
  

  const exportMenu = (
    <Menu>
      <Menu.Item key="export-json" onClick={exportSelectedRowsJson}>
        Export to JSON
      </Menu.Item>
      <Menu.Item key="export-csv" onClick={exportSelectedRowsCsv}>
        Export to CSV
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item key="edit-title" onClick={() => {
        setNewTitle(form?.name);
        setIsModalVisible(true);
      }}>
        Edit Form Name
      </Menu.Item>
      <Menu.Item key="delete-form">
        <Popconfirm
          placement="topLeft"
          title="Delete Form"
          description="Are you sure? After deleting the form all data will be gone forever."
          onConfirm={deleteForm}
          okText="Yes"
          cancelText="No"
        >
          Delete Form
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  if (!form) {
    return <div><LoadingComponent /></div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Navbar />
      <div style={{width: '100%', maxWidth: 1200, userSelect: 'none', paddingTop: 100, display: 'flex', flexDirection: 'row', justifyContent:'flex-start', gap: 8,  alignItems: 'center', }}>
        <p className="backToForms" onClick={() => navigate('/')}>Forms</p> <p className='sub' style={{paddingTop: 4}}> ﹥ </p> <p style={{fontWeight:'bold'}}>{form.name}</p>
      </div>
      <div style={{width: '100%', maxWidth: 1200, paddingTop: 24}}>
        <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Modal
            title="Edit Title"
            visible={isModalVisible}
            onOk={handleTitleSave}
            onCancel={() => setIsModalVisible(false)}
            okText="Save"
            cancelText="Cancel"
          >
            <Input type="text" value={newTitle} onChange={handleTitleChange} />
          </Modal>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4, flexDirection: 'row'}}>
            {licenseStatus === null && (
              <div className="freeTier">
                <div className='icon'><p>!</p></div>
                <p>You are currently on a free plan. <a href="/pricing">Upgrade</a> to use integrations, remove the MagicForm badge on your forms and more.</p>
              </div>
            )}
          </div>
        </div>
        <div style={{borderBottom: '1px solid #2A2A2C', borderBottomWidth: submissions.length === 0 ? 1 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 16, paddingBottom: 8}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 16}}>
            <NavLink to={`/forms/${formId}`} className={isActive(`/forms/${formId}`) ? "navItem activeNavItem" : "navItem" }>
              <p>Submissions</p>
            </NavLink>
            <NavLink to={`/forms/${formId}/settings`} className={isActive(`/forms/${formId}/settings`) ? "navItem activeNavItem" : "navItem" }>
              <p>Notifications</p>
            </NavLink>
            <NavLink to={`/forms/${formId}/integrations`}
              className={isActive(`/forms/${formId}/integrations`) ? "navItem activeNavItem" : "navItem" }>
              <p>Integrations</p>
            </NavLink>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 8}}>
            <Dropdown overlay={menu}>
              <Button><SettingOutlined /> Edit form</Button>
            </Dropdown>
            <Button icon={<CopyOutlined />} onClick={copyToClipboard}>Copy Form ID</Button>
            {selectedRowKeys.length > 0 && (
              <div style={{display: 'flex', gap: 8}}>
                <Dropdown overlay={exportMenu}>
                  <Button>
                    <CloudDownloadOutlined /> Export
                  </Button>
                </Dropdown>
                <Popconfirm 
                  placement="topRight"
                  title="Delete submissions"
                  description="Are you really sure? This cannot be undone."
                  onConfirm={confirmDelete}
                  onCancel={cancelDelete}
                  okText="Yes, delete"
                  cancelText="No, cancel"
                >
                  <Button icon={<DeleteOutlined />} type="primary" danger>Delete {selectedRowKeys.length} row(s)</Button>
                </Popconfirm>
              </div>
            )}
          </div>
        </div>
        <div style={{paddingBottom: 80 }}>
          {submissions.length === 0 ? (
            <div className='newFormOnboarding'>
              <div className="framerComponent">
                <h3>Select a template to use on your Framer site</h3>
                <p style={{fontSize: 14, paddingTop: 6, paddingBottom: 24, color: '#808080'}}>Copy the URL below and paste this into your Framer project. The MagicForm component will be added to your canvas.</p>
                <Select
                  defaultValue={codeSnippets[0].label}
                  style={{ width: '100%', marginBottom: 8, marginTop: 4 }}
                  onChange={(value) => {
                    const selected = codeSnippets.find(snippet => snippet.label === value);
                    setSelectedSnippet(selected.snippet);
                    setPreviewUrl(selected.previewUrl);
                  }}
                >
                  {codeSnippets.map((item, index) => (
                    <Option key={index} value={item.label}>{item.label}</Option>
                  ))}
                </Select>
                <pre><code>{selectedSnippet}</code></pre>
                <div className="rowDir" style={{ alignItems: 'center', gap: 4 }}>
                  <Button size="small" style={{ width: '60%' }} onClick={copyComponentToClipboard} icon={<CopyOutlined />}>
                    Copy component
                  </Button>
                  <Button
                    size="small"
                    onClick={() => window.open(previewUrl, '_blank', 'noopener,noreferrer')}
                    style={{ width: '40%' }}
                  >
                    Preview template
                  </Button>
                </div>
              </div>
              <div className="getStarted">
                <div className='rowDir' style={{justifyContent: 'space-between'}}>
                  <h3>Getting started</h3>
                  <Button
                    size="small"
                    onClick={() => window.open('https://getmagicform.com/academy/get-started', '_blank', 'noopener,noreferrer')}
                  >
                    View doc
                  </Button>
                </div>
                <p style={{fontSize: 14, paddingTop: 6, paddingBottom: 24, color: '#808080'}}>It's not hard to setup MagicForm, but it<br/>can be nice to have some help.</p>
                <img src="/getstarted.png"/>
              </div>
            </div>
          ) : (
            <div style={{
              overflowX: 'auto',
              width: '100%',
              border: tableColumns.length > 5 ? '1px solid #2A2A2C' : null,
              borderRadius: tableColumns.length > 5 ? 0 : null,
            }}>
              <Table
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                rowClassName={(record) => {
                  const count = Object.keys(record.data).filter(key => key !== 'email').length - 1;
                  return count > 0 ? 'clickable-row' : '';
                }}
                pagination={{
                  position: [bottom],
                  ...pagination,
                  onChange: (page, pageSize) => {
                    setPagination({ current: page, pageSize });
                    handleTableChange({ current: page, pageSize });
                  },
                }}
                style={{
                  minWidth: tableColumns.length > 5 ? 'max-content' : '100%',
                  border: tableColumns.length > 5 ? null : '1px solid #2A2A2C',
                  borderRadius: tableColumns.length > 5 ? null : 0,
                }}
                expandRowByClick={true}
                expandable={{
                  expandedRowRender: (record) => {
                    const dataKeys = Object.keys(record.data);
                    let otherKeys, urlAndUtmKeys;
  
                    if (productName?.includes("Analytics")) {
                      urlAndUtmKeys = dataKeys.filter(key => 
                        key.startsWith('url') || 
                        key.startsWith('utm_') || 
                        ['_gcl_aw', '_ga', '_fbp', '_hstc'].includes(key)
                      );
                      otherKeys = dataKeys.filter(key => 
                        !key.startsWith('url') && 
                        !key.startsWith('utm_') && 
                        !['_gcl_aw', '_ga', '_fbp', '_hstc'].includes(key)
                      );
                    } else {
                      otherKeys = dataKeys.filter(key => 
                        !key.startsWith('url') && 
                        !key.startsWith('utm_') && 
                        key !== 'email'
                      );
                      urlAndUtmKeys = [];
                    }
  
                    const sortedKeys = [...otherKeys, ...urlAndUtmKeys];
  
                    return (
                      <div className="expandedRow">
                        {sortedKeys.map(key => {
                          const value = typeof record.data[key] === 'boolean' ? (record.data[key] ? 'Yes' : 'No') : record.data[key];
                          return (
                            <div key={key}>
                              <p className="key">{key}:</p>
                              <p className="value" onClick={() => copyValueToClipboard(value.toString())}>{value}</p>
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                  rowExpandable: (record) => Object.keys(record.data).length > 1,
                  expandIcon: () => null,
                }}
                dataSource={submissions.map((submission, index) => ({ ...submission, key: index }))}
                columns={tableColumns}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FormDetail;
