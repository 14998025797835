import React, { createContext, useState, useEffect } from 'react';
import supabase from './supabaseClient';



export const UserContext = createContext();

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [licenseStatus, setLicenseStatus] = useState(''); // Add this line
  const [loading, setLoading] = useState(true); // add loading state
  const [userDetailsLoaded, setUserDetailsLoaded] = useState(false);  
  const [userFormAccess, setUserFormAccess] = useState({});

  

  useEffect(() => {
    const sessionFromStorage = localStorage.getItem('supabase.auth.token');
    if (sessionFromStorage) {
      const parsedSession = JSON.parse(sessionFromStorage);
      supabase.auth.setSession(parsedSession.access_token); // Make sure to use the correct property for the token
      setUser(parsedSession.user);
    }
    setLoading(false);
  }, []);

  // Set up the Supabase auth listener
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        localStorage.setItem('supabase.auth.token', JSON.stringify(session));
        setUser(session.user);
      } else {
        localStorage.removeItem('supabase.auth.token');
        setUser(null);
      }
    });

    return () => {
      if (authListener?.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  async function fetchUserDetails(userId) {
    const { data } = await supabase
      .from('users')
      .select('first_name, last_name, status') // added 'status'
      .eq('id', userId);
  
    if (data.length > 0) {
      const { first_name, last_name, status } = data[0]; // added 'status'
      setFirstName(first_name);
      setLastName(last_name);
      setLicenseStatus(status); // Add this line
    }
  }


  async function fetchUserDetails(userId) {
    // ... existing code to fetch user details
    setUserDetailsLoaded(true); // Set this to true once the user details have been fetched
  }
  


// Function to check if the current user has access to a specific form
const checkFormAccess = async (formId) => {
  // Early return if no user is logged in
  if (!user) return false;

  // Check if we've already determined access for this formId to avoid redundant queries
  if (userFormAccess[formId] !== undefined) {
    return userFormAccess[formId];
  }

  try {
    // Query the forms table for all forms that belong to the current user
    const { data, error } = await supabase
      .from('forms')
      .select('form_id') // Only select the form_id since that's what we're interested in
      .eq('user_id', user.id);

    // If there's an error or no data, handle accordingly
    if (error || !data) {
      console.error('Error checking form access:', error);
      return false; // Default to no access on error
    }

    // Check if the specific formId is among the user's forms
    const hasAccess = data.some(form => form.form_id === formId);

    // Update the local state cache with the new access information
    setUserFormAccess(prev => ({ ...prev, [formId]: hasAccess }));

    return hasAccess;
  } catch (error) {
    console.error('Error checking form access:', error);
    return false; // Consider how to handle errors - perhaps defaulting to no access or showing an error message
  }
};




  return (
    <UserContext.Provider value={{ user, firstName, lastName, loading, licenseStatus, userDetailsLoaded, checkFormAccess }}>
      {children}
    </UserContext.Provider>
  );
}
