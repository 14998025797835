import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import supabase from '../supabaseClient';
import { Input, message, Button } from 'antd';
import { UserContext } from '../UserContext';

function Setup() {
  const { user, loading } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [inputFirstName, setInputFirstName] = useState(user ? user.firstName : '');
  const [inputLastName, setInputLastName] = useState(user ? user.lastName : '');
  const [userId, setUserId] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  

  
  async function handleLogout() {
    console.log('Clicked!') 
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Error signing out:', error);
        alert(error.message);
      }
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Error signing out');
    }
  }


  useEffect(() => {
    const sessionSubscription = supabase.auth.onAuthStateChange((event, session) => {
      setUserId(session?.user?.id);
    });

    // Cleanup function
    return () => {
      if (sessionSubscription?.unsubscribe) {
        sessionSubscription.unsubscribe();
      } else if (sessionSubscription?.remove) {
        sessionSubscription.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchUserData = async () => {
        const { data, error } = await supabase
          .from('users')
          .select('first_name, last_name')
          .eq('id', userId)
          .single();
  
        if (error) {
          console.error('Error fetching user data:', error);
        } else {
          // Update your context or local state
          setInputFirstName(data.first_name);
          setInputLastName(data.last_name);
        }
      };
  
      fetchUserData();
    }
  }, [userId]);

  async function handleNameUpdate(e) {
    e.preventDefault();
    let errors = {
      firstName: inputFirstName.length < 2,
      lastName: inputLastName.length < 2,
    };

    // Check if there are any errors
    if (errors.firstName || errors.lastName) {
      // If there are, set the error states and return
      setFirstNameError(errors.firstName);
      setLastNameError(errors.lastName);

      if (errors.firstName && errors.lastName) {
        console.error("Both first name and last name should be at least 2 characters.");
        message.warning("Both first name and last name should be at least 2 characters.");
      } else {
        if (errors.firstName) {
          console.error("First name should be at least 2 characters.");
          message.warning("First name should be at least 2 characters.");
        }
        if (errors.lastName) {
          console.error("Last name should be at least 2 characters.");
          message.warning("Last name should be at least 2 characters.");
        }
      }

      return;
    }

    try {
      const { error } = await supabase
        .from('users')
        .update({ first_name: inputFirstName, last_name: inputLastName })
        .eq('id', userId);

      if (error) {
        throw error;
      }

      console.log('Success! Names updated.');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error updating names:', error);
      alert(error.error_description || error.message);
    }
  }

  return (
          <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  }}
>
  <div
    style={{
      maxWidth: '400px',
      width: '100%',
      padding: '24px',
    }}
  >
    <div style={{display: 'flex', gap: 4, flexDirection: 'column', marginBottom: 40}}>
    <img src="/magicform_white.png"  alt="magicArm" style={{width: 100, marginBottom: 40}}/> 
      <h2>Enter your name</h2>
      <p className="sub">Please enter your name in the fields below</p>
    </div>


      <form onSubmit={handleNameUpdate}>
        <div style={{display: 'flex', gap: 4, flexDirection: 'column', marginBottom: 16 }}>
        <h4>First name</h4>
        <Input
          type="text"
          placeholder="Enter your first name..."
          value={inputFirstName}
          onChange={(e) => {
            setInputFirstName(e.target.value);
            setFirstNameError(e.target.value.length < 2);
          }}
          status={firstNameError ? "error" : ""}
        />
        </div>
        <div style={{display: 'flex', gap: 4, flexDirection: 'column', marginBottom: 16 }}>
        <h4>Last name</h4>
        <Input
          type="text"
          placeholder="Enter your last name..."
          value={inputLastName}
          onChange={(e) => {
            setInputLastName(e.target.value);
            setLastNameError(e.target.value.length < 2);
          }}
          status={lastNameError ? "error" : ""}
        />
        </div>
        <Button style={{width: '100%'}} type="primary" htmlType="submit">
          Submit
        </Button>
      </form>
      <div style={{paddingTop: 10, textAlign: 'center', cursor: 'pointer'}} onClick={handleLogout}><p className="sub" style={{fontSize: 12}}>Back to login</p></div>
      </div>
      </div>
  );
}

export default Setup;
