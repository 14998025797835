import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase  from '../supabaseClient';
import { Input, message, Button } from 'antd';
import { UserContext } from '../UserContext';
import Navbar from '../pages/Navbar';

function Profile() {
  const [email, setEmail] = useState('');
    const {user, firstName: initialFirstName, lastName: initialLastName } = useContext(UserContext);
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [userId, setUserId] = useState(null);
  const [licenseKey, setLicenseKey] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const navigate = useNavigate(); 
  


useEffect(() => {
  const sessionSubscription = supabase.auth.onAuthStateChange((event, session) => {
    setUserId(session?.user?.id);
  });

  // Cleanup function
  return () => {
    if (sessionSubscription?.unsubscribe) {
      sessionSubscription.unsubscribe();
    } else if (sessionSubscription?.remove) {
      sessionSubscription.remove();
    }
  };
}, []);


useEffect(() => {
  setFirstName(initialFirstName || '');
  setLastName(initialLastName || '');
}, [initialFirstName, initialLastName]);






















 async function handleLogout() {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Error signing out:', error);
        alert(error.message);
      }
    } catch (error) {
      console.error('Error signing out:', error);
      alert('Error signing out');
    }
  }

  async function handleEmail(e) {
    e.preventDefault();

    try {
      
const { data, error } = await supabase.auth.updateUser({email: email});
      console.log('Response:', user, error);

      if (error) {
        throw error;
      }

      console.log('Success! Email updated:', user);
    } catch (error) {
      console.error('Error setting up:', error);
      alert(error.error_description || error.message);
    }
  }

  async function handleNameUpdate(e) {
    e.preventDefault();
       let errors = {
    firstName: firstName.length < 2,
    lastName: lastName.length < 2
  };

  // Check if there are any errors
  if (errors.firstName || errors.lastName) {
    // If there are, set the error states and return
    setFirstNameError(errors.firstName);
    setLastNameError(errors.lastName);
    
       if (errors.firstName && errors.lastName) {
      console.error("Both first name and last name should be at least 2 characters.");
      message.warning("Both first name and last name should be at least 2 characters.");
    } else {
      if (errors.firstName) {
        console.error("First name should be at least 2 characters.");
        message.warning("First name should be at least 2 characters.");
      }
      if (errors.lastName) {
        console.error("Last name should be at least 2 characters.");
        message.warning("Last name should be at least 2 characters.");
      }
    }
    
    return;
  }

    try {
      const { error } = await supabase
        .from('users')
        .update({ first_name: firstName, last_name: lastName })
        .eq('id', userId);

      if (error) {
        throw error;
      }

      console.log('Success! Names updated.');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error updating names:', error);
      alert(error.error_description || error.message);
    }
  }

  return (
     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
      <Navbar />
      <div style={{width: '100%', paddingBottom: 40, maxWidth: 1200, userSelect: 'none', paddingTop: 100, display: 'flex', flexDirection: 'row', justifyContent:'flex-start', gap: 8,  alignItems: 'center', }}>
        <p className="backToForms" onClick={() => navigate('/')}>Forms</p> <p className='sub' style={{paddingTop: 4}}> ﹥ </p> <p style={{fontWeight:'bold'}}>Profile</p>
        </div>
      

      
<div style={{width: '100%', maxWidth: 500}}>
  
    


        


  
      <form onSubmit={handleNameUpdate}>

      <div style={{display: 'flex', gap: 12, flexDirection: 'row', width: '100%', alignItems: 'flex-end', padding: '40px 0px',  }}>
        <div style={{display: 'flex', gap: 8, width: '-webkit-fill-available', flexDirection: 'column', }}>
          <h4>Firstname</h4>
          <Input
            type="text"
            placeholder="Enter your firstname..."
            value={firstName}
             onChange={(e) => {
              setFirstName(e.target.value);
              setFirstNameError(e.target.value.length < 2);
            }}
            status={firstNameError ? "error" : ""}
          />
        </div>
         
         <div style={{display: 'flex', gap: 8, width: '-webkit-fill-available', flexDirection: 'column'}}>
        <h4>Lastname</h4>
        <Input
          type="text"
          placeholder="Enter your lastname..."
          value={lastName}
           onChange={(e) => {
            setLastName(e.target.value);
            setLastNameError(e.target.value.length < 2);
          }}
          status={lastNameError ? "error" : ""}
        />
        </div>
        <Button style={{}} type="primary" htmlType="submit">Submit</Button>
        </div>
      </form>

      
    </div>
    
    </div>
  );
}

export default Profile;
