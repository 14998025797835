import React, { useEffect, useState, useContext,  useCallback, useRef } from 'react';
import { useParams, NavLink, useNavigate, Link } from 'react-router-dom'; 
import { Input, Modal, Drawer, Form, Switch, Select, Button, Space, Radio, Divider, Popconfirm, message, Menu, Dropdown } from 'antd';
import supabase from '../supabaseClient';
import Navbar from '../pages/Navbar';
import { SettingOutlined, ExclamationCircleFilled, ArrowUpOutlined, EditOutlined, DeleteOutlined, CopyOutlined, SaveOutlined } from '@ant-design/icons';
import LoadingComponent from '../components/LoadingComponent';
import { UserContext } from '../UserContext';
import { LicenseContext } from '../LicenseContext';
import { IntegryJS } from "@integry/sdk";


function FormIntegrations() {
  const [form, setForm] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const { user, checkFormAccess } = useContext(UserContext);
  const { formId } = useParams();
  const [hasAccess, setHasAccess] = useState(false);
  const [editingTitle, setEditingTitle] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [selectionType, setSelectionType] = useState("checkbox");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [integrationDetails, setIntegrationDetails] = useState({});
  const [activeIntegrations, setActiveIntegrations] = useState({});
  const [authCompleted, setAuthCompleted] = useState(false);
  const [mappingDetails, setMappingDetails] = useState({});
  const [submissionKeys, setSubmissionKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { licenseStatus } = useContext(LicenseContext);

  const integryContainer = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (licenseStatus === null) {
      const timeoutId = setTimeout(() => {
        message.warning("Submissions to integrations only work on paid plans");
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  const initializeIntegry = async (hash, appKey) => {
    const data = await getSubmissionWithMostKeys();

    const integryHandle = new IntegryJS({
      appKey,
      hash,
      userId: formId, 
      xIntegryConfig: {
        appAuth: {
          apiKey: null,
        },
      },
      options: {
        objects: {
          Submission: data,
        },
      },
    });

    integryHandle.init({
      containerId: "integryContainer",
      showApps: true,
      renderMode: IntegryJS.RenderModes.INLINE,
      viewStyle: IntegryJS.ViewStyles.COMFORTABLE,
    });

    integryHandle.eventEmitter.on("ready", (data) => {
      console.log("Integry SDK ready", data);
    });

    integryHandle.eventEmitter.on("did-save-integration", (data) => {
      console.log("Adding integration…", data)

      if (data.event === "CREATE") {
        addIntegryIntegration({
          integrationId: data.integrationId,
          authorizationId: data.authorizations[0].authorizationId,
          webhookUrl: data.callbackUrl,
          integrationName: data.name,
          active: true,
        });
      }
    });

    integryHandle.eventEmitter.on("did-enable-integration", (data) => {
      console.log("Enable integration…", data)

      toggleStatusIntegryIntegration(data.integrationId, data.status)
    });
    
    integryHandle.eventEmitter.on("did-disable-integration", (data) => {
      console.log("Disable integration…", data)

      toggleStatusIntegryIntegration(data.integrationId, data.status)
    });
 
    integryHandle.eventEmitter.on("did-delete-integration", (data) => {
      console.log("Deleting integration…", data)

      deleteIntegryIntegration(data.integrationId, "integrationId")
    });

    integryHandle.eventEmitter.on("did-remove-authorization", (data) => {
      console.log("Remove authorization…", data)

      deleteIntegryIntegration(data.authorizationId, "authorizationId")
    });
  };

  

  const addIntegryIntegration = async (integryWebhookData) => {
    try {
      const { data: prevData, error: prevDataError } = await supabase
        .from("forms")
        .select("integry")
        .eq("form_id", formId)
        .single();

      if (prevDataError) {
        throw prevDataError;
      }
      
      const updatedIntegrations = [...(prevData?.integry || []), integryWebhookData];

      const { error } = await supabase
        .from("forms")
        .update({ integry: updatedIntegrations })
        .eq("form_id", formId);

      if (error) {
        throw error;
      }

      message.success("Authentication details are saved");
    } catch (error) {
      console.error("Error updating authentication details:", error);
      message.error("Something went wrong");
    }
  };

  const toggleStatusIntegryIntegration = async (integrationId, status) => {
    const newStatus = status !== "INACTIVE";

    try {
      const { data, getError } = await supabase
        .from("forms")
        .select("integry")
        .eq("form_id", formId)
        .single();

      if (getError) {
        throw getError;
      }
      const integrations = data.integry;

      let integration = integrations.find(
        (obj) => obj["integrationId"] === integrationId
      );
      if (integration) integration["active"] = newStatus;

      const { error } = await supabase
        .from("forms")
        .update({ integry: integrations })
        .eq("form_id", formId);

      if (error) {
        throw error;
      }

      message.success("Integration status updated");
    } catch (error) {
      console.error("Error updating integration status:", error);
      message.error("Something went wrong");
    }
  };

  const deleteIntegryIntegration = async (value, field) => {
    try {
      const { data: prevData, prevDataError } = await supabase
        .from("forms")
        .select("integry")
        .eq("form_id", formId)
        .single();

      if (prevDataError) {
        throw prevDataError;
      }

      const updatedIntegrations = [...(prevData?.integry || [])].filter(
        (integration) => integration[field] !== value
      );

      const { error } = await supabase
        .from("forms")
        .update({
          integry:
            updatedIntegrations.length !== 0 ? updatedIntegrations : null,
        })
        .eq("form_id", formId);

      if (error) {
        throw error;
      }

      message.success("Integration details are removed");
    } catch (error) {
      console.error("Error removing integration details:", error);
      message.error("Something went wrong");
    }
  };

  const getSubmissionWithMostKeys = async () => {
    try {
      const { data, error } = await supabase
        .from("submissions")
        .select("*")
        .select("data")
        .eq("form_id", formId)
        .order("created_at", { ascending: false })
        .limit(30);

      if (error) {
        throw error;
      }

      const mostKeys = data
        .map((obj) => obj.data)
        .sort((a, b) => Object.keys(b).length - Object.keys(a).length)[0];

      Object.keys(mostKeys).forEach((i) => (mostKeys[i] = null));
      if (!mostKeys.url && !Array.isArray(mostKeys.url)) delete data.url;

      return mostKeys;
    } catch (error) {
      console.error("Error fetching latest submission:", error);
    }
  };



useEffect(() => {
  const init = async () => {
    if (!user) return;
    
    try {
      const url = `https://magicform.onrender.com/api/integryHash/${formId}`
      // const url = `http://localhost:3001/api/integryHash/${formId}`
      
      const response = await fetch(url);
      
      if (response.ok) {
        const data = await response.json();
        initializeIntegry(data.hash, data.appKey);
      } else {
        const errorText = await response.text(); 
        throw new Error('Failed to fetch details: ' + errorText);
      }
      
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };

  if(integryContainer.current) init()

}, [user, integryContainer.current]);



const isActive = (path) => {
  return window.location.pathname === path;
};

const SERVICE_INPUTS = {

 activeCampaign: {
  displayName: 'Active Campaign', 
 integrationDetails: 'Automatically add submissions to your contacts in Active Campaign',
 authMessage: "To setup your integration with Active Campaign we need a few details.",
 authLink: "https://developers.activecampaign.com/reference/authentication",
 mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
 mapLink: "https://getmagicform.com/",
 auth: [
  { name: "api_url", label: "API URL", required: true, }, 
  { name: "api_key", label: "API Key", required: true, },
 ],
 mapping: [
   { name: "email", label: "Email", required: true },
   { name: "firstName", label: "Firstname", required: false },
   { name: "lastName", label: "Lastname", required: false },
   { name: "phone", label: "Phone", required: false },
   ],
},


beehiiv: {
  displayName: 'Beehiiv', 
 integrationDetails: 'Automatically add subscribers to your audience in Beehiiv',
 authMessage: "To setup your integration with Beehiiv, all we need is your API Key and the Publication ID of the audience where you want your contacts to be added.",
 authLink: "https://support.apphub.com/en/articles/7885211-beehiiv",
 mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
 mapLink: "https://getmagicform.com/",
 auth: [
   { name: "api_key", label: "API Key", required: true, },
   { name: "publication_id", label: "Publication ID", required: true, },
 ],
 mapping: [
   { name: "email", label: "Email", required: true },
   ],
},

brevo: {
  displayName: 'Brevo', 
 integrationDetails: 'Automatically add submissions to your contacts in Brevo',
 authMessage: "To setup your integration with Brevo all we need is your API Key.",
 authLink: "https://app.brevo.com/settings/keys/api",
 mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
 mapLink: "https://getmagicform.com/",
 auth: [
  { name: "api_key", label: "API Key", required: true, },
  { name: "listIds", label: "List ID", required: false, },

 ],
 mapping: [
   { name: "email", label: "Email", required: true },
   { name: "FIRSTNAME", label: "Firstname", required: false },
   { name: "LASTNAME", label: "Lastname", required: false },
   ],
},


convertkit: {
  displayName: 'ConvertKit', 
 integrationDetails: 'Automatically add submissions to your subscriber list in ConvertKit',
 authMessage: "To setup your integration with Mailchimp, all we need is your API Key and the Form ID where you want your contacts to be added.",
 authLink: "https://www.google.com/search?sxsrf=AB5stBhDbPKLrOyTs3cGIX9khkCTVhStzA:1690749158464&q=get+api+key+convertkit&spell=1&sa=X&ved=2ahUKEwij_fm3o7eAAxW7i_0HHamuAOIQBSgAegQICRAB&biw=1708&bih=1027&dpr=2",
 mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
 mapLink: "https://getmagicform.com/",
 auth: [
   { name: "api_key", label: "API Key", required: true, },
   { name: "form_id", label: "Form ID", required: true, },
 ],
 mapping: [
   { name: "email", label: "Email", required: true },
   { name: "first_name", label: "Firstname", required: false },
   ],
},

  customWebhook: {
     displayName: 'Custom Webhook', 
    integrationDetails: 'Enter a custom webhook url to forward your submissions to',
    authMessage: "Setup your custom webhook to easily forward the submission data towards another place somewhere on the web.",
    authLink: "https://chat.openai.com/share/dba6292b-9e03-41ab-8d1a-e3dfb0585b7c",
    auth: [
      { name: "webhookUrl", label: "Webhook URL", required: true },
    ],
    mapping: [],
  },

  
 hubspot: {
  displayName: 'Hubspot', 
 integrationDetails: 'Automatically add submissions to your contacts in Hubspot',
 authMessage: "To setup your integration with Hubspot you first need to create a new app in Hubspot.",
 authLink: "https://developers.hubspot.com/docs/api/private-apps",
 mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
 mapLink: "https://getmagicform.com/",
 auth: [
  { name: "api_key", label: "API Key", required: true, },
 ],
 mapping: [
   { name: "email", label: "Email", required: true },
   { name: "firstname", label: "Firstname", required: false },
   { name: "lastname", label: "Lastname", required: false },
   ],
},


  loops: {
    displayName: 'Loops', 
   integrationDetails: 'Automatically add submissions to your contacts in Loops',
   authMessage: "To setup your integration with Loops, all we need is your API Key.",
   authLink: "https://loops.so/docs/api#authentication",
   mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
   mapLink: "https://getmagicform.com/",
   auth: [
     { name: "api_key", label: "API Key", required: true, },
   ],
   mapping: [
     { name: "email", label: "Email", required: true },
     { name: "firstName", label: "Firstname", required: false },
     { name: "lastName", label: "Lastname", required: false },
     { name: "userGroup", label: "Usergroup", required: false },
     ],
 },

  mailchimp: {
     displayName: 'Mailchimp', 
    integrationDetails: 'Automatically add submissions to your audience in Mailchimp',
    authMessage: "To setup your integration with Mailchimp, all we need is your API Key and the ID of the audience where you want your contacts to be added.",
    authLink: "https://mailchimp.com/help/about-api-keys/",
    mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
    mapLink: "https://getmagicform.com/",
    auth: [
      { name: "api_key", label: "API Key", required: true},
      { name: "audience_id", label: "Audience ID", required: true },
    ],
    mapping: [
      { name: "EMAIL", label: "Email", required: true },
      { name: "FNAME", label: "Firstname", required: false },
      { name: "LNAME", label: "Lastname", required: false },
      { name: "PHONE", label: "Phone", required: false },
    ],
  },

  mailerlite: {
    displayName: 'MailerLite', 
   integrationDetails: 'Automatically add submissions to your subscribers in MailerLite',
   authMessage: "To setup your integration with MailerLite all we need is your API Key.",
   authLink: "https://dashboard.mailerlite.com/integrations",
   mapMessage: "Every integration uses it own naming conventials. To make sure MagicForm understand which fields it should send the data we politely ask you to map your fields.",
   mapLink: "https://getmagicform.com/",
   auth: [
    { name: "api_key", label: "API Key", required: true, },
   ],
   mapping: [
     { name: "email", label: "Email", required: true },
     { name: "name", label: "Name", required: false },
     ],
  },

   zapier: {
     displayName: 'Zapier', 
    integrationDetails: 'Send your submissions data straight towards your Zapier endpoint',
    authMessage: "Enter your Zapier webhook to start receiving submission data inside your Zapier account",
    authLink: "https://help.zapier.com/hc/en-us/articles/8496288690317-Trigger-Zaps-from-webhooks#1-add-a-webhook-trigger-0-1",
    auth: [
      { name: "webhookUrl", label: "Webhook URL", required: true },
    ],
    mapping: [],
  },
 
};



useEffect(() => {
  const verifyAccess = async () => {
    const access = await checkFormAccess(formId);
    setHasAccess(access);
    if (!access) {
      // Redirect if no access
      navigate('/');
    }
  };

  verifyAccess();
}, [formId, checkFormAccess, navigate]);




 
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };




const handleAuthOk = async () => {
  try {
    const currentInputs = SERVICE_INPUTS[currentService];
    let authData = {};

    for (let input of currentInputs.auth) {
      if (input.required && !integrationDetails[input.name]) {
        message.error(`Please fill out ${input.label}`);
        return;
      }
      authData[input.name] = integrationDetails[input.name];
    }

    const { data: prevData } = await supabase
      .from('forms')
      .select('integrations')
      .eq('form_id', formId)
      .single();

    const prevIntegrations = prevData.integrations || {};

    const { error } = await supabase
      .from('forms')
      .update({ 
        integrations: { 
          ...prevIntegrations, 
          [currentService]: {
             ...authData,
            active: true
          } 
        }
      })
      .eq('form_id', formId);
    if (error) {
      throw error;
    }
    message.success('Authentication details are saved');
    setActiveIntegrations(prev => ({ 
      ...prev, 
      [currentService]: {
        ...authData,
        active: true

      }
    })); 
        setAuthCompleted(true);
  } catch (error) {
    console.error('Error updating authentication details:', error);
    message.error('Something went wrong');
  }
};

const handleMappingOk = async () => {
  try {
    const currentInputs = SERVICE_INPUTS[currentService]; 
    let field_mapping = {};

    for (let input of currentInputs.mapping) {
      if (input.required && !mappingDetails[currentService][input.name]) {
        message.error(`Please fill out ${input.label}`);
        return;
      }
      field_mapping[input.name] = mappingDetails[currentService][input.name];
    }
    
    const { data: prevData } = await supabase
      .from('forms')
      .select('integrations')
      .eq('form_id', formId)
      .single();

    const prevIntegrations = prevData.integrations || {};

    const { error } = await supabase
      .from('forms')
      .update({ 
        integrations: { 
          ...prevIntegrations, 
          [currentService]: {
            ...prevIntegrations[currentService],  // Include existing service details
            field_mapping,
            active: true
          } 
        }
      })
      .eq('form_id', formId);
    if (error) {
      throw error;
    }
    message.success('Mapping details are saved');
    setActiveIntegrations(prev => ({ 
      ...prev, 
      [currentService]: {
        ...prev[currentService],  // Include existing service details
        field_mapping,
        active: true
      }
    })); 
    setOpen(false);
  } catch (error) {
    console.error('Error updating mapping details:', error);
    message.error('Something went wrong');
  }
};


const handleMappingChange = (value, name) => {
  setMappingDetails(prev => ({
    ...prev,
    [currentService]: {
      ...(prev[currentService] || {}),
      [name]: value,
    },
  }));
};




const handleCancel = () => {
  setOpen(false);
};


const editIntegration = (service) => {
  setCurrentService(service);
  setIntegrationDetails(activeIntegrations[service] || {});
  showDrawer();
};


const deleteIntegration = async (service) => {
  try {
    const { data: prevData } = await supabase
      .from('forms')
      .select('integrations')
      .eq('form_id', formId)
      .single();

    const prevIntegrations = prevData.integrations || {};

    // Delete the integration from the previous integrations
    delete prevIntegrations[service];

    const { error } = await supabase
      .from('forms')
      .update({ integrations: prevIntegrations })
      .eq('form_id', formId);
    if (error) {
      throw error;
    }

     setIntegrationDetails({});
    setActiveIntegrations({});
    setMappingDetails({});


    message.success('Integration details are removed');
    window.location.reload();

  } catch (error) {
    console.error('Error removing integration details:', error);
    message.error('Something went wrong');
  }
};

const toggleIntegrationStatus = async (service) => {
  const { data: prevData, error } = await supabase
    .from('forms')
    .select('integrations')
    .eq('form_id', formId)
    .single();

  if (error) {
    console.error('Error fetching form:', error);
    message.error('Something went wrong');
    return;
  }

  const updatedIntegrations = {
    ...prevData.integrations,
    [service]: {
      ...prevData.integrations[service],
      active: !prevData.integrations[service].active,
    }
  };

  const { error: updateError } = await supabase
    .from('forms')
    .update({ integrations: updatedIntegrations })
    .eq('form_id', formId);

  if (updateError) {
    console.error('Error updating integration status:', updateError);
    message.error('Something went wrong');
  } else {
    setActiveIntegrations(updatedIntegrations);
  }
};

useEffect(() => {
  if (open && currentService) {
    setIntegrationDetails(activeIntegrations[currentService] || {});
  }
}, [open, currentService, activeIntegrations]);


  const deleteForm = async () => {
    try {
      const { error } = await supabase.from('forms').delete().eq('form_id', formId);
      if (error) {
        throw error;
      }
      message.success('Form deleted successfully');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error deleting form:', error);
      message.error('Failed to delete form');
    } finally {
      setDeleteLoading(false);
    }
  };





const copyToClipboard = useCallback(() => {
  navigator.clipboard.writeText(formId)
    .then(() => {
      message.success('Form ID copied to clipboard');
    })
    .catch(() => {
      message.error('Failed to copy Form ID to clipboard');
    });
}, [formId]);

  const handleTitleEdit = () => {
    setNewTitle(form.name);
    setEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    try {
      await supabase.from('forms').update({ name: newTitle }).eq('form_id', formId);
      setForm((prevForm) => ({ ...prevForm, name: newTitle }));
      setEditingTitle(false);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error updating form title:', error);
      message.error('Failed to update form title');
    }
  };

  useEffect(() => {
  const fetchForm = async () => {
    const { data, error } = await supabase.from('forms').select('*').eq('form_id', formId).single();
    if (error) {
      console.error('Error fetching form:', error);
    } else {
      setForm(data);
      setActiveIntegrations(data.integrations || {});

      // Load mapping details into state
      if (data.integrations) {
        Object.entries(data.integrations).forEach(([service, details]) => {
          if (details.field_mapping) {
            setMappingDetails(prev => ({
              ...prev,
              [service]: details.field_mapping,
            }));
          }
        });
      }
      
      const { data: submissionData, error: submissionError } = await supabase
        .from('submissions')
        .select('*')
        .eq('form_id', formId);

      if (submissionError) {
        console.error('Error fetching submissions:', submissionError);
      } else {
        setSubmissions(submissionData);

        let allKeys = new Set();
        submissionData.forEach(submission => {
          // Here is the change, we target submission.data
          if (submission.data) {
            Object.keys(submission.data).forEach(key => allKeys.add(key));
          }
        });
        setSubmissionKeys(Array.from(allKeys));
        
        Object.values(SERVICE_INPUTS).forEach(serviceInputs => {
          serviceInputs.mapping = submissionKeys.map(key => ({ name: key, label: `Mapping for '${key}'`, required: false }));
        });
      }
    }
  };
  fetchForm();
}, [formId]);




 

const menu = (
  <Menu>
           <Menu.Item key="edit-title"  onClick={() => {
  setNewTitle(form?.name); // Set newTitle to the current form's name
  setIsModalVisible(true); // Then, show the modal
}}>
        Edit Form Name
      </Menu.Item>
      <Menu.Item key="delete-form">
        <Popconfirm
             placement="topLeft"
          title="Delete Form"
          description="Are you sure? After deleting the form all data will be gone forever."
          onConfirm={deleteForm}
          okText="Yes"
          cancelText="No"
        >
          Delete Form
        </Popconfirm>
      </Menu.Item>
    </Menu>
);

  if (!form) {
    return <div><LoadingComponent /></div>;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', }}>
      <Navbar />
      <div style={{width: '100%', maxWidth: 1200, userSelect: 'none', paddingTop: 100, display: 'flex', flexDirection: 'row', justifyContent:'flex-start', gap: 8,  alignItems: 'center', }}>
        <p className="backToForms" onClick={() => navigate('/')}>Forms</p> <p className='sub' style={{paddingTop: 4}}> ﹥ </p> <p style={{fontWeight:'bold'}}>{form.name}</p>
        </div>
      <div style={{width: '100%', maxWidth: 1200, paddingTop: 24}}>
     

      <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <Modal
  title="Edit Title"
  visible={isModalVisible}
  onOk={handleTitleSave} // Assuming handleTitleSave not only saves but also hides the modal
  onCancel={() => setIsModalVisible(false)}
  okText="Save"
  cancelText="Cancel"
>
  <Input type="text" value={newTitle} onChange={handleTitleChange} />
</Modal>

        

        
        </div>  

        <div style={{borderBottom: '1px solid #2A2A2C', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: 16, paddingBottom: 8}}>
         <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 16}}>
          <NavLink to={`/forms/${formId}`} className={isActive(`/forms/${formId}`) ? "navItem activeNavItem" : "navItem" }>
            <p>Submissions</p>
            
          </NavLink>
      
      
          
          <NavLink to={`/forms/${formId}/settings`} className={isActive(`/forms/${formId}/settings`) ? "navItem activeNavItem" : "navItem" }>
            <p>Notifications</p>
          </NavLink>

          <NavLink to={`/forms/${formId}/integrations`}
            className={isActive(`/forms/${formId}/integrations`) ? "navItem activeNavItem" : "navItem" }>
            <p >Integrations </p>
          </NavLink>
          </div>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 8}}>
          <Dropdown overlay={menu}>

<Button><SettingOutlined /> Edit form</Button>
 </Dropdown>
          <Button  icon={<CopyOutlined />} onClick={copyToClipboard}>Copy Form ID</Button>
          
          </div>
        </div>


     <div>

     
     <div style={{paddingTop: 24}}>
     {licenseStatus === null && (<Link to="/pricing" ><span className="paidPlanBadge">Upgrade plan</span></Link>)}
        <h3 className="gap4">Integrate in your workflow</h3>
         <p className="sub" style={{maxWidth: '60%', }}>We want you to enable you to get the most out of your data. That's why we offer over 250+ integrations with the most known tools in the world.</p>
       
      </div>
      

     <div ref={integryContainer} id="integryContainer" />


    
    
      
        
         
      
    
     


{Object.entries(activeIntegrations).length >= 1 &&
     <div style={{paddingBottom: 80, paddingTop: 12}}>
     <div>
      
        <h3 className="gap4">Active Integrations</h3>
         <p className="sub" style={{maxWidth: '60%', }}>Overview of your active integrations. You can easily pause or continue your active subscriptions and setup the mapping for the formfields.</p>
         <p className="sub" style={{maxWidth: '60%', color: '#ff5f00', paddingTop: 12 }}>The integrations below will be sunsetted at the end of the month of August. We advice you to setup the integration by selecting a partner to integrate with on the top of this page.</p>
      </div>
    
     <div style={{ 
          marginTop: 24,
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gridGap: '16px' // sets the gap between grid items, change as needed
      }}>
      
  {Object.entries(activeIntegrations).map(([service, details]) => {

  if (service === "integryWebhooks") return null

  const displayName = SERVICE_INPUTS[service]?.displayName;
  const requiredMapping = SERVICE_INPUTS[service]?.mapping.filter(item => item.required);
  const isMappingIncomplete = requiredMapping.some(item => !details.field_mapping?.[item.name]);


  return (
        <div  className="integration" key={service}>
            
            <div className="top"> 
            
              <img src={`/${service}.png`} alt={service} />
          
                <div style={{display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 8 }}>
                  <p className="service" style={{fontWeight: 'bold'}}>{displayName}</p>
                    {service === 'customWebhook' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}>Webhook URL: {details.webhookUrl}</p>}
                    {service === 'mailchimp' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}>Audience ID: {details.audience_id}</p>}
                    {service === 'convertkit' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}>Form ID: {details.form_id}</p>}
                    {service === 'loops' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}></p>}
                    {service === 'beehiiv' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}></p>}
                    {service === 'brevo' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}>Form ID: {details.listIds}</p>}
                    {service === 'activeCampaign' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}></p>}
                    {service === 'hubspot' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}></p>}
                    {service === 'mailerlite' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}></p>}
                    {service === 'zapier' && <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}></p>}
                </div>
              </div>
          
            <div className="bottom">
              <div className="actions">
                
                <Switch checked={details.active} onChange={() => toggleIntegrationStatus(service)} />

                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 4}}>
                     
                         {SERVICE_INPUTS[service]?.mapping.length > 0 && (
                            isMappingIncomplete
                            ? <Button danger icon={<EditOutlined />} onClick={() => editIntegration(service)}>Mapping incomplete</Button>
                            : <Button icon={<EditOutlined />} onClick={() => editIntegration(service)}></Button>
                          )}

                    <Button icon={<DeleteOutlined />} onClick={() => deleteIntegration(service)}></Button>
                
                </div>
                
              </div>
            </div>
        
        </div>
      )})}
</div>
</div>
}


   <Drawer 
  title="Integration settings" 
  placement="right" 
  onClose={onClose} 
  open={open} 
  bodyStyle={{ paddingBottom: 80 }}
  extra={
    <Space>
      <Button onClick={authCompleted ? handleMappingOk : handleAuthOk} type="primary">Save</Button>
    </Space>
  }
>
  <div>
      
        <h3 style={{paddingBottom: 4}}>{SERVICE_INPUTS[currentService]?.displayName || ''}</h3>

        {activeIntegrations[currentService]?.active 
        ?
        <div className="authMessage"> 
         <p className="sub small">{SERVICE_INPUTS[currentService]?.mapMessage}</p>
          { activeIntegrations[currentService]?.active && submissionKeys.length === 0 &&
    <div className="noDataIntegration">
      <ExclamationCircleFilled  style={{fontSize: 18, color: '#ff0020'}}/>
      <p className="small" style={{color: '#ff0020'}}>There is no data in the submissions table. Make sure there is atleast one submission in your form.</p>
    </div>
}
         <Button target="blank" style={{width: '100%'}} icon={<ArrowUpOutlined className="rotate-icon" />} href={SERVICE_INPUTS[currentService]?.mapLink}>More about mapping</Button>
         </div>
        : 
        <div className="authMessage">
          <p className="sub small">{SERVICE_INPUTS[currentService]?.authMessage}</p>
          <Button  target="blank" style={{width: '100%'}} icon={<ArrowUpOutlined className="rotate-icon" />} href={SERVICE_INPUTS[currentService]?.authLink}>Get help</Button>
          </div>
      }
  </div>


 
  <Form>
    {(activeIntegrations[currentService]?.active 
      ? SERVICE_INPUTS[currentService]?.mapping 
      : SERVICE_INPUTS[currentService]?.auth
    )?.map((input) => (
      activeIntegrations[currentService]?.active
      ? <Form.Item required={input.required}>
            <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 16 }}>
              <h4>{input.label}</h4>
              <Select 
              placeholder="Select a value"
                onChange={(value) => handleMappingChange(value, input.name)}
                value={mappingDetails[currentService] ? mappingDetails[currentService][input.name] : undefined}
                >
                {submissionKeys.map(key => <Select.Option value={key}>{key}</Select.Option>)}
              </Select>
            </div>
        </Form.Item>
      : <Form.Item key={input.name}>
          <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 16 }}>
              <h4>{input.label}</h4>
              <Input
              placeholder="Enter your value"
                value={integrationDetails[input.name] || ''}
                onChange={(e) =>
                  setIntegrationDetails((prev) => ({
                      ...prev,
                      [input.name]: e.target.value,
                  }))
                }
              />
            </div>
        </Form.Item>
    ))}
  </Form>
</Drawer>

<div style={{paddingTop: 24}}>
<h3 className="gap4">Select your integrations</h3>
<p className="sub" style={{maxWidth: '60%', }}>Why doing manual labor if you can automate it. With integrations you magically send the data you receive inside the submissions forward to your other tools.</p>
</div>
<div style={{ 
  marginTop: 24,
  paddingBottom: 40,
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gridGap: '16px' // sets the gap between grid items, change as needed
}}>
 {
  Object.entries(SERVICE_INPUTS)
    .filter(([service, {inputs}]) => !Object.keys(activeIntegrations).includes(service))
    .map(([service, {inputs, displayName, integrationDetails}]) => (
      <div className="integration"  key={service}>
        <div className="top">
        <img src={`/${service}.png`} alt={service} />
        <div style={{display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 8 }}>
          <p className="service" style={{fontWeight: 'bold'}}>{displayName}</p>
          <p className="sub small" style={{height: 56, maxWidth: '90%', lineHeight: '20px'}}>{integrationDetails}</p>
        </div>
        </div>
        <div className="bottom">
          <Button onClick={() => {setCurrentService(service);showDrawer();}}>Connect</Button>
        </div>
      </div>
    ))
}
</div>


</div>


      </div>
    
    </div>
  );
}

export default FormIntegrations;
