import React, { createContext, useState, useEffect } from 'react';
import supabase from './supabaseClient';
export const LicenseContext = createContext();

export const LicenseProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [licenseStatus, setLicenseStatus] = useState(null);
  const [productName, setProductName] = useState(null);
  const [licenseLoading, setLicenseLoading] = useState(true);
  const [submissionCount, setSubmissionCount] = useState(true);
  const [formsCount, setFormsCount] = useState(true);
  const [hasFinishedOnboarding, setHasFinishedOnboarding] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
const [productId, setProductId] = useState(null);
const [variantId, setVariantId] = useState(null);

  const updateOnboardingStatus = (status) => {
    // Update the hasFinishedOnboarding state
    setHasFinishedOnboarding(status);
};


  useEffect(() => {
    const sessionSubscription = supabase.auth.onAuthStateChange((event, session) => {
      
      setUserId(session?.user?.id);
    });

    // Cleanup function
    return () => {
      if (sessionSubscription?.unsubscribe) {
        sessionSubscription.unsubscribe();
      } else if (sessionSubscription?.remove) {
        sessionSubscription.remove();
      }
    };
  }, []);

  useEffect(() => {
    setLicenseLoading(true);
    const fetchData = async () => {
      if (userId) {
        const { data, error } = await supabase
          .from('users')
          .select('status, product_name, total_submissions, total_forms, has_finished_onboarding, subscription_id, product_id, variant_id')
          .eq('id', userId);
      
        if (error) {
          console.error('Error fetching user status:', error);
        } else if (data && data.length > 0) {
          setLicenseStatus(data[0].status);
          setProductName(mapProductNameToTier(data[0].product_name)); // Set simplified tier name
          setSubmissionCount(data[0].total_submissions);
          setFormsCount(data[0].total_forms);
          setHasFinishedOnboarding(data[0].has_finished_onboarding);
          setSubscriptionId(data[0].subscription_id);
          setProductId(data[0].product_id);
          setVariantId(data[0].variant_id);
        }
      }
      setLicenseLoading(false);
    };
  
    fetchData();
  }, [userId]);
  

  const updateLicenseData = async () => {
    if (userId) {
      const { data, error } = await supabase
        .from('users')
        .select('status, product_name, total_submissions, total_forms, subscription_id, product_id, variant_id')
        .eq('id', userId);
      
      if (error) {
        console.error('Error fetching user status:', error);
      } else if (data && data.length > 0) {
        setLicenseStatus(data[0].status);
        setProductName(mapProductNameToTier(data[0].product_name)); // Set simplified tier name
        setSubmissionCount(data[0].total_submissions);
        setFormsCount(data[0].total_forms);
        setSubscriptionId(data[0].subscription_id);
        setProductId(data[0].product_id);
        setVariantId(data[0].variant_id);
      }
    }
  };
    
  const nameMapping = {
    "MagicForm - Apprentice": "MagicForm - Basic",
    "MagicForm - Apprentice + Files": "MagicForm - Basic + Files",
    "MagicForm - Spellbinder": "MagicForm - Essentials",
    "MagicForm - Spellbinder + Files": "MagicForm - Essentials + Files",
    "MagicForm - Wizard": "MagicForm - Pro",
    "MagicForm - Wizard + Files": "MagicForm - Pro + Files",
    "MagicForm - Analytics": "MagicForm - Analytics",
    "MagicForm - Analytics + Files": "MagicForm - Analytics + Files",
  };
  
  const mapProductNameToTier = (productName) => {
    return nameMapping[productName] || productName;
  };
  
  

  


  return (
    <LicenseContext.Provider value={{
      userId, licenseStatus, productName, licenseLoading, nameMapping, mapProductNameToTier, submissionCount, formsCount, hasFinishedOnboarding, updateOnboardingStatus, setHasFinishedOnboarding, setLicenseLoading, setLicenseStatus, setProductName, setSubmissionCount, setFormsCount, updateLicenseData, subscriptionId, productId, variantId
    }}>
      {children}
    </LicenseContext.Provider>
    
  );
};
