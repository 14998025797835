import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, message, Button } from 'antd';
import supabase from '../supabaseClient';
import { nanoid } from 'nanoid';
import { LicenseProvider, LicenseContext } from '../LicenseContext'; 
import { UserContext } from '../UserContext'

function Onboarding() {
    const navigate = useNavigate();
    const [formName, setFormName] = useState('');
    const { user } = useContext(UserContext);
    const defaultFormNames = ["Contact Form", "Signup Form", "Waitinglist", "Newsletter", "Subscribers"];
    const selectDefaultFormName = (name) => {
        setFormName(name);
      };

    
    const createForm = async (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
      
        if (!formName) {
          message.error('Please enter a form name.');
          return;
        }
      
        try {
            const formId = nanoid(10);
            console.log("Generated formId:", formId); // This should log the generated ID

          const currentDate = new Date();
      
          const { data, error } = await supabase
            .from('forms')
            .insert([{ 
              form_id: formId, 
              name: formName, 
              date: currentDate, 
              user_email: user?.email,
            send_email_on_submit: true, 
              user_id: user?.id, // Assuming user ID is available from the context
              // Add other form details as needed
            }]);
      
          if (error) {
            throw error;
          }
      
          message.success('Form created successfully');
          navigate('/get-template' , { state: { formId }});
    
        } catch (error) {
          console.error('Error creating form:', error);
          message.error('Error creating form');
        }
      };
 

  return (
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        
        flexWrap: 'wrap', // Allow the items to wrap to the next line on smaller screens
      }}>
        
      
     
    
       <div style={{
          flex: '1 0 auto', // Take up remaining space
          width: '100%',
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          boxSizing: 'border-box',
          '@media (max-width: 768px)': { // On small screens
            width: '100%', // Take up the full width
          },
      }}
    >
     
  
  
  
      <div className="signup" style={{width: '100%', maxWidth: 500}}>
      
        <div style={{ display: 'flex', gap: 4, flexDirection: 'column', alignItems: 'flex-start', marginBottom: 40 }}>
        <img src="/magicform_white.png"  alt="magicArm" style={{width: 100, marginBottom: 40}}/> 
        
          <h2>Enter a name for your first form</h2>
          <p className="sub">This will be used to host your submissions.</p>
        </div>
  
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'space-between', marginBottom: '8px'  }}>
  {defaultFormNames.map(name => (
    <Button size="small" key={name} onClick={() => selectDefaultFormName(name)}>
      {name}
    </Button>
  ))}
</div>

        <form onSubmit={createForm}>
  <div className='colDir' style={{ gap: 8}}>
    
    <Input
      type="text"
      placeholder="Enter a name for your form"
      value={formName}
      onChange={(e) => setFormName(e.target.value)}
    />
    <Button style={{ width: '100%' }} type="primary" htmlType="submit">
    Create Form
  </Button>
  </div>
  
</form>

  
</div>
  
  </div>
  
  </div>
  );
}

export default Onboarding;
