import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, message, Button } from 'antd';
import supabase from '../supabaseClient';
import { UserContext } from '../UserContext'
import { LicenseProvider, LicenseContext } from '../LicenseContext'; 
function EndOnboarding() {
    const location = useLocation();
    const { user } = useContext(UserContext);
     const formId = location.state?.formId; // Access formId from the location state
     const navigate = useNavigate();
     const { updateOnboardingStatus, updateLicenseData } = useContext(LicenseContext); 
     
     
     
     const handleEndOnboarding = async () => {
   
        console.log("User ID:", user?.id);
        try {
            const { error } = await supabase
                .from('users')
                .update({ has_finished_onboarding: true })
                .eq('id', user?.id);
    
            if (error) {
                console.error('Failed to update user:', error);
                // Handle the error appropriately (e.g., show an error message to the user)
            } else {
                updateOnboardingStatus(true);
                await updateLicenseData();
                navigate('/'); // Navigate only after a successful update
            }
        } catch (error) {
            console.error('Error during update:', error);
            // Handle any additional errors appropriately
        }
    };
    

    const copyToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          message.success('Copied to clipboard!');
        } catch (err) {
          console.error('Failed to copy!', err);
          message.error('Failed to copy!');
        }
      };
      

  return (
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        
        flexWrap: 'wrap', // Allow the items to wrap to the next line on smaller screens
      }}>
        
      
     
    
       <div style={{
          flex: '1 0 auto', // Take up remaining space
          width: '100%',
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          boxSizing: 'border-box',
          '@media (max-width: 768px)': { // On small screens
            width: '100%', // Take up the full width
          },
      }}
    >
     
  
  
  
      <div className="signup" style={{width: '100%', maxWidth: '600px' }}>
    
        <div style={{ display: 'flex', gap: 4, flexDirection: 'column', alignItems: 'flex-start', marginBottom: 40 }}>
        <img src="/magicform_white.png"  alt="magicArm" style={{width: 100, marginBottom: 40}}/> 
        <h2>Enter your FormID</h2>
        <p className="sub">All that's left to do is paste your FormID onto your component in Framer.</p>
        <div className='colDir' style={{marginBottom: 40, display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8, justifyContent: 'flex-start',marginTop: 24}}>
          <Button  onClick={() => copyToClipboard(formId)}>Copy Form ID</Button>
          <Button style={{width: 'fit-content'}}  onClick={() => window.open('https://getmagicform.com/academy', '_blank', 'noopener,noreferrer')}>Visit our Academy</Button>
        </div>
        <img 
    src="/formid.png"  
    alt="magicArm" 
    style={{
        width: '100%', 
        borderRadius: '12px', 
        boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px 0px rgba(0, 0, 0, 0.02)'
    }}
/>
<div className='colDir' style={{marginTop: 24}}>
        <Button onClick={handleEndOnboarding} type="primary" style={{width: 'fit-content'}}>End onboarding</Button>
        
        </div>

</div>
</div>
  
  </div>
  
  </div>
  );
}

export default EndOnboarding;
