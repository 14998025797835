import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, message, Button } from 'antd';
import supabase from '../supabaseClient';

function Login({session}) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

     useEffect(() => {
    if (session) {
      // Redirect to '/'
      navigate(`/`);
    }
  }, [session]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        throw error;
      }

      console.log('Success! Logged in.');
      window.location.href = '/'; // Redirect to '/'
    } catch (error) {
      console.error('Error logging in:', error);
        message.warning(error.error_description || error.message); 
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      
      flexWrap: 'wrap', // Allow the items to wrap to the next line on smaller screens
    }}>
    
   
  
     <div style={{
        flex: '1 0 auto', // Take up remaining space
        width: '100%',
        padding: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box',
        '@media (max-width: 768px)': { // On small screens
          width: '100%', // Take up the full width
        },
    }}
  >
   

    <div className="signup" style={{width: '100%', maxWidth: 500}}>
      <div style={{ display: 'flex', gap: 4, flexDirection: 'column', alignItems: 'flex-start', marginBottom: 40 }}>
      <img src="/magicform_white.png" className="logo" alt="Logo" />
        <h2>Welcome back</h2>
        <p className="sub">Discover the easiest way to receive <br/>form submissions for your Framer site.</p>
      </div>



    <form onSubmit={handleLogin}>
      <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
        <h4>Email</h4>
        <Input
          type="email"
          placeholder="info@getmagicform.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
        <h4>Password</h4>
        <Input.Password
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div style={{display: 'flex', alignItems: 'flex-end', flexDirection:'column', gap: 12}}>
        <Button style={{width: '100%'}} type="primary" htmlType="submit">
          Log in
        </Button>
        <Link className="link password" to="/password_request">Forgot password?</Link>
        </div>
    </form>
    <div style={{
        marginTop: 40,
        display:'flex',
        gap: 6,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',}}>
    <p >New to MagicForm</p>
    
    <Link className="link" to="/signup">Get started</Link>
    
    
  </div>
  </div>
  </div>
</div>
  );
}

export default Login;
