import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Input, Checkbox, Select, message, Drawer, Button } from 'antd';
import { SearchOutlined, CopyOutlined } from '@ant-design/icons';
import Navbar from '../pages/Navbar';
import { nanoid } from 'nanoid';
import supabase from '../supabaseClient';
import { UserContext } from '../UserContext'
import { LicenseProvider, LicenseContext } from '../LicenseContext'; 


function Forms() {
  
  const [formName, setFormName] = useState('');
  const [forms, setForms] = useState([]);
  const { formId } = useParams();
  const [totalForms, setTotalForms] = useState(0);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { licenseStatus, productName, formsCount } = useContext(LicenseContext);
  const [sendEmailOnSubmit, setSendEmailOnSubmit] = useState(licenseStatus !== null); // Set to true by default
  const [emailInput, setEmailInput] = useState('');
  const [otherRecipients, setOtherRecipients] = useState([]);
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [displayedForms, setDisplayedForms] = useState([]);
  const [dropTargetId, setDropTargetId] = useState(null);
  

const handleDragStart = (e, id) => {
  setDraggedItemId(id);
  
};

const handleDrop = (e, dropTargetId) => {
  e.preventDefault(); // Prevent the default behavior
  const newOrder = [...displayedForms];
  const draggedItemIndex = newOrder.findIndex(item => item.form_id === draggedItemId);
  const dropTargetIndex = newOrder.findIndex(item => item.form_id === dropTargetId);

  if (draggedItemIndex >= 0 && dropTargetIndex >= 0) {
    const [reorderedItem] = newOrder.splice(draggedItemIndex, 1);
    newOrder.splice(dropTargetIndex, 0, reorderedItem);
    console.log("Drop target ID:", dropTargetId);

    setDisplayedForms(newOrder);

    // Optionally, save this order in local storage or database here.
    const newOrderIds = newOrder.map(item => item.form_id);
    localStorage.setItem('formOrder', JSON.stringify(newOrderIds));
  }
};

const handleDragOver = (e, id) => {
  e.preventDefault(); // This is necessary to allow dropping.

  setDropTargetId(id); // Update the drop target ID as the item is dragged over different targets.
};



const handleDragEnd = () => {
  setDropTargetId(null); // Clear the drop target indicator.
};


const saveFormOrder = (forms) => {
  const formOrder = forms.map(form => form.form_id);
  localStorage.setItem('formOrder', JSON.stringify(formOrder));
};

useEffect(() => {
  const fetchFormsAndUpdateOrder = async () => {
    if (user?.id) {
      // Fetch current forms from your backend or source
      const { data: fetchedForms, error } = await supabase
        .from('forms')
        .select('*')
        .eq('user_id', user?.id);

      if (error) {
        console.error('Error fetching forms:', error);
        return;
      }

      setForms(fetchedForms);
      setTotalForms(fetchedForms.length);

      // Retrieve savedOrder from localStorage
      const savedOrder = JSON.parse(localStorage.getItem('formOrder')) || [];
      

      // Check and update savedOrder if necessary
      const savedOrderIds = new Set(savedOrder);
      const updatedOrder = [...savedOrder];

      fetchedForms.forEach(form => {
        if (!savedOrderIds.has(form.form_id)) {
          updatedOrder.push(form.form_id); // Add new forms to the end of the order
        }
      });

      if (updatedOrder.length !== savedOrder.length) {
        localStorage.setItem('formOrder', JSON.stringify(updatedOrder));
        
      }
      
      // Now apply the updated order to displayedForms
      const orderedForms = updatedOrder.map(id => fetchedForms.find(form => form.form_id === id)).filter(form => form != null);
      setDisplayedForms(orderedForms.length > 0 ? orderedForms : fetchedForms);
    }
  };

  fetchFormsAndUpdateOrder();
}, [user?.id]); // This effect depends on the user's id



  const canCreateNewForm = () => {
    // Check for the base case where any plan allows creating at least one form
    if (formsCount < 1) return true;
  
    // Assuming "Basic" and "Basic + Files" replace "MagicForm - Apprentice" and "MagicForm - Apprentice + Files"
    if ((productName === "MagicForm - Basic" || productName === "MagicForm - Basic + Files") && formsCount < 1) return true;
  
    // Assuming "Essentials" and "Essentials + Files" replace "MagicForm - Spellbinder" and "MagicForm - Spellbinder + Files"
    if ((productName === "MagicForm - Essentials" || productName === "MagicForm - Essentials + Files") && formsCount < 3) return true;
  
    // Assuming "Pro" and "Analytics" plans allow unlimited forms
    // Note: Adjusted based on your new naming scheme; add "+ Files" variants if they exist and allow unlimited forms.
    if (productName === "MagicForm - Pro" || productName === "MagicForm - Analytics" || productName === "MagicForm - Pro + Files" || productName === "MagicForm - Analytics + Files") {
        return true;
    }
  
    // If none of the above conditions are met, do not allow creating a new form
    return false;
  };
  



  const emailsToArray = (emails) => {
    if (!emails) return [];
    return emails.split(',').map(email => email.trim());
  }
  
  
  
  const handleSendEmailOnSubmitChange = async (e) => {
      const checked = e.target.checked;
      setSendEmailOnSubmit(checked);
      await updateSendEmailOnSubmitSetting(checked);
    };
  
  const handleEmailInputChange = (event) => {
    setEmailInput(event.target.value);
  }
  
    const handleEmailSubmit = async () => {
    if(emailInput) {
      const newEmails = [...otherRecipients, emailInput];
      await updateOtherRecipientsSetting(newEmails);
      setEmailInput('');
    }
  }
  
  const handleEmailRemove = async (emailToRemove) => {
    const newEmails = otherRecipients.filter(email => email !== emailToRemove);
    await updateOtherRecipientsSetting(newEmails);
  }
  
  
  const updateSendEmailOnSubmitSetting = async (sendEmailOnSubmit) => {
      try {
        const { data, error } = await supabase.from('forms').update({ 
          send_email_on_submit: sendEmailOnSubmit
        }).eq('form_id', formId);
  
        if (error) {
          throw error;
        }
      } catch (error) {
        console.error('Error updating send email on submit setting:', error);
        message.error('Failed to update send email on submit setting');
      }
    };
  
  
  const updateOtherRecipientsSetting = async (emails) => {
    try {
      // emails is already an array, no need to join
      const { data, error } = await supabase.from('forms').update({ 
        other_recipients: emails
      }).eq('form_id', formId);
  
      if (error) {
        throw error;
      }
      setOtherRecipients(emails);
      message.success('Other recipients updated successfully');
    } catch (error) {
      console.error('Error updating other recipients:', error);
      message.error('Failed to update other recipients');
    }
  };
  
  
  



  const showDrawer = () => {
    setIsDrawerVisible(true); // Replacing showModal
  };
  

  const handleDrawerSubmit = async () => {
    setConfirmLoading(true);

    const formId = nanoid(10);

    const currentDate = new Date();

    const { data, error } = await supabase
      .from('forms')
      .insert([{ form_id: formId, 
                  name: formName, 
                  date: currentDate,  
                  user_id: user?.id,
                  user_email: user?.email,
                  send_email_on_submit: sendEmailOnSubmit,
         
                }]);

    if (error) {
      console.error('Error creating form:', error);
      alert('Error creating form');
    } else {
      console.log('Form created:', data);
      localStorage.setItem('toastMessage', "Form created successfully, you can now setup your email notifications in settings");
      navigate(`/forms/${formId}`);
    }

    setFormName('');
    setIsDrawerVisible(false);
    setConfirmLoading(false);
  };

  const handleDrawerClose = () => {
    setIsDrawerVisible(false); // Replacing handleCancel
  };



  useEffect(() => {
    const fetchForms = async () => {
      if (user?.id) {
        const { data, error } = await supabase
          .from('forms')
          .select('*')
          .eq('user_id', user?.id);
        if (error) {
          console.error('Error fetching forms:', error);
        } else {
          data.forEach((form) => {
            if (!localStorage.getItem(form.form_id)) {
              localStorage.setItem(form.form_id, form.submission_count);
            }
          });
          setForms(data);
          setTotalForms(data.length);
        }
      }
    };

    const fetchSubmissions = async () => {
      if (user?.id) {
        const { data: submissionsData, error: submissionsError } = await supabase
          .from('forms')
          .select('submission_count')
          .eq('user_id', user?.id);
        if (submissionsError) {
          console.error('Error fetching submissions:', submissionsError);
        } else {
          const total = submissionsData.reduce((acc, curr) => acc + curr.submission_count, 0);
          setTotalSubmissions(total);
        }
      }
    };

    fetchForms();
    fetchSubmissions();
  }, [user?.id]);

    const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredForms = forms.filter(form =>
    form.name.toLowerCase().includes(searchTerm.toLowerCase())
  );




  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <Navbar />

      <div style={{width: '100%', maxWidth: 1200, paddingTop: 140}}>
        <div style={{ marginBottom: 24, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <h2>Forms</h2>
           <div className='rowDir' style={{gap: 8}}>
            <div className='searchBox'>
                <SearchOutlined style={{color: '#f9f9f9', fontSize: 14}}/>
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearch}
                  style={{
                    fontSize: '14px',
                    border: 'none',
                    color: '#111',
                    outline: 'none',
                    boxShadow: 'none',
                    
                  }}
                />
            </div>
            <Button  type="primary" onClick={showDrawer}>Create new form</Button>
            </div>  
        </div>

        
    
    
    
    
    
        <Drawer
        
        className='drawer'
      title="Create Form"
      open={isDrawerVisible}
      onClose={handleDrawerClose}
      footer={
        canCreateNewForm() && <div className='colDir' style={{backgroundColor: '#111', gap: 8, padding: '12px 0px' }}>
          
        
          <Button onClick={handleDrawerClose}>Cancel</Button>
          <Button type="primary" onClick={handleDrawerSubmit}>Submit</Button>
          
        </div>
      }
    >
      {canCreateNewForm() ? (
       <div className='colDir' style={{gap: 24}}>
        
          <div className='colDir' style={{gap: 4}}>
            <h5>Enter form name<span className="required"> *</span></h5> 
            <Input
              type="text"
              placeholder="Form Name"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
            />
          </div>

          <div className='colDir' style={{gap: 4}}>
            
            <div className='rowDir' style={{justifyContent: 'space-between', gap: 8}}>
                <p className='small' style={{color: '#f9f9f9'}}>Receive notifications in your email
                  {licenseStatus === null && (
                    <span className="paidPlanBadge">Paid plan feature</span>
                  )}
                </p>  
                
                <Checkbox className='checkbox' disabled={licenseStatus === null} checked={sendEmailOnSubmit} style={{ transform: 'scale(0.8)' }}  onChange={handleSendEmailOnSubmitChange}/>
            </div>
      </div>
      

    
   </div>




        ) : (
          <div>
            <p className='small' style={{ paddingBottom: 12 }}>
              You've reached the maximum amount for your current plan. Please upgrade to a higher level to create more forms.
            </p>
            <Button type="primary"
            onClick={() => window.location.href='/pricing'}>Upgrade Now</Button>
          </div>
          
        )}
        
        </Drawer>



       
        

        {user && (
          <div className="forms" style={{paddingBottom: '80px'}}>
            
            <div className='colDir' style={{gap: 12}}>
            {displayedForms.map(form => {
                const isDropTarget = form.form_id === dropTargetId;
              const currentCount = form.submission_count;
              const lastCount = localStorage.getItem(form.form_id);
              const unreadCount = currentCount - lastCount;
              const integrationsStr = JSON.stringify(form.integrations);
              const isActiveAnywhere = integrationsStr.includes('"active":true');

              return (
                <div
                className={`formRow ${isDropTarget ? "drop-target" : ""}`}
                  key={form.form_id}
                  draggable="true"
                  onDragStart={(e) => handleDragStart(e, form.form_id)}
                  onDragOver={(e) => handleDragOver(e, form.form_id)}
                  onDrop={(e) => handleDrop(e, form.form_id)}
                  onDragEnd={handleDragEnd}
                  onClick={() => {
                    localStorage.setItem(form.form_id, form.submission_count);
                    navigate(`/forms/${form.form_id}`);
                  }}
                >
                  
                  <div className='data title' >
                    <h5>Title</h5>
                    <p>{form.name}</p>
                  </div>
                
                
                
                  
                

                    <div className='data'>
                    <h5>Submissions</h5>
                    <div className='rowDir' style={{gap: 8}}>
                      <p>{form.submission_count}</p>
                      {unreadCount >= 1 && (
                        <div className="unreadCountActive">
                          <span>New</span>
                        </div>
                      )}
                    </div>
                    </div>


                    <div className='data'>
  <h5 style={{paddingBottom: '2px'}}>Email Notifications</h5>    
  {form.send_email_on_submit ? (
    <img src="/check1.png" className="checkmark"   />
  ) : (
    <img src="/no-check.png" className="checkmark"  />
  )}
</div>


                    


                    <div className='data'>
                    <h5>Form ID</h5>    
                    <p className="formId">{form.form_id}</p>
                    </div>

                    <div className='data' style={{flexGrow: 0, alignItems: 'flex-end', height: 34, justifyContent: 'center'}}>
                    <div>
                      <img src="/dots-six-vertical.png" draggable="false" style={{userSelect: 'none', width: 20}}/>
                      </div>
                    </div>

                 
                </div>
              )
            })}
            </div>
          </div>
      
        )}
      
      </div>
    </div>
  );
}

export default Forms;
