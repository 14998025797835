// test product names
// implement product names on pages: form details, forms, 
// implement files en url in mail

import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { UserContext } from '../UserContext';
import { LicenseContext } from '../LicenseContext';
import { Input, Modal, Checkbox, Dropdown, Menu, Select, Button, message } from 'antd';
import Navbar from '../pages/Navbar';
import { mixpanelTrack } from '../mixpanelConfig'; 

const Pricing = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {user, firstName: initialFirstName, lastName: initialLastName } = useContext(UserContext);
  const {licenseStatus,productName, mapProductNameToTier, nameMapping, formsCount, variantId,submissionCount,updateLicenseData, subscriptionId} = useContext(LicenseContext);
  const [licenseKey, setLicenseKey] = useState('');
  const [userId, setUserId] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null); // New state for error message
  const navigate = useNavigate(); 
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] = useState(false);
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [selectedTier, setSelectedTier] = useState(null);
const [showConfirmation, setShowConfirmation] = useState(false);
const [changeDirection, setChangeDirection] = useState(null);
const [consentGiven, setConsentGiven] = useState(false);
const currentDisplayName = mapProductNameToTier(productName);


  const productOptions = [
    "MagicForm - Basic",
    "MagicForm - Basic + Files",
    "MagicForm - Essentials",
    "MagicForm - Essentials + Files",
    "MagicForm - Pro",
    "MagicForm - Pro + Files",
    "MagicForm - Analytics",
    "MagicForm - Analytics + Files",
  ];

  

  const mapVariantNameAndBillingCycleToId = (variantName, billingCycle) => {
    console.log("Direct Variant Name:", variantName); // Debug log
    console.log("Direct Billing Cycle:", billingCycle); // Debug log
    const variantId = variantMap[variantName]?.[billingCycle];
    console.log("Directly Mapped Variant ID:", variantId); // Debug log
    return variantId;
  };
  
  

  
  const variantMap = {
    "MagicForm - Basic": {
      monthly: "140902", // Assuming these IDs remain the same
      yearly: "138844",
    },
    "MagicForm - Basic + Files": {
      monthly: "220889",
      yearly: "220893",
    },
    "MagicForm - Essentials": {
      monthly: "140901",
      yearly: "120401",
    },
    "MagicForm - Essentials + Files": {
      monthly: "220899",
      yearly: "220900",
    },
    "MagicForm - Pro": {
      monthly: "140900",
      yearly: "138845",
    },
    "MagicForm - Pro + Files": {
      monthly: "220902",
      yearly: "220907",
    },
    // Assuming "MagicForm - Analytics" and "MagicForm - Analytics + Files" remain unchanged
    "MagicForm - Analytics": {
      monthly: "218845",
      yearly: "218852",
    },
    "MagicForm - Analytics + Files": {
      monthly: "220886",
      yearly: "220887",
    },
  };
  
    const determineTierChangeDirection = (currentTier, selectedTier) => {
      const tiers = Object.keys(variantMap); // Accessible here as well
      const currentIndex = tiers.indexOf(currentTier);
      const selectedIndex = tiers.indexOf(selectedTier);
    
      return selectedIndex > currentIndex ? 'up' : 'down';
    };
    

    const tierMaxForms = {
      'MagicForm - Basic': 1,
      'MagicForm - Basic + Files': 1,
      'MagicForm - Essentials': 3,
      'MagicForm - Essentials + Files': 3,
      'MagicForm - Pro': "Unlimited",
      'MagicForm - Pro + Files': "Unlimited",
      'MagicForm - Analytics': "Unlimited",
      'MagicForm - Analytics + Files': "Unlimited"
    };
    

  let tierMax = tierMaxForms[productName] || 1;



  const menuBasic = (
    <Menu>
      <Menu.Item key="1" onClick={() => {
        // Track the click and open the URL for "with files"
        mixpanelTrack('Basic LS With Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/4ca06033-4785-4f21-8708-7bca5ba9e22e', '_blank');
      }}>
        With Files
      </Menu.Item>
      <Menu.Item key="2" onClick={() => {
        // Track the click and open the URL for "without files"
        mixpanelTrack('Basic LS Without Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/4489249c-9c24-48e7-b431-db8639cb896d', '_blank');
      }}>
        Without Files
      </Menu.Item>
    </Menu>
  );
  
  const menuEssentials = (
    <Menu>
      <Menu.Item key="1" onClick={() => {
        // Track the click and open the URL for "with files"
        mixpanelTrack('Essentials LS With Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/19033355-458d-40f4-84d2-9c0583f9f2da', '_blank');
      }}>
        With Files
      </Menu.Item>
      <Menu.Item key="2" onClick={() => {
        // Track the click and open the URL for "without files"
        mixpanelTrack('Essentials LS Without Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/d4f57455-8a19-4e63-b6a7-7de6bc09741d', '_blank');
      }}>
        Without Files
      </Menu.Item>
    </Menu>
  );
  
  const menuPro = (
    <Menu>
      <Menu.Item key="1" onClick={() => {
        // Track the click and open the URL for "with files"
        mixpanelTrack('Pro LS With Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/4e890351-f21a-4541-9ac7-43848615347e', '_blank');
      }}>
        With Files
      </Menu.Item>
      <Menu.Item key="2" onClick={() => {
        // Track the click and open the URL for "without files"
        mixpanelTrack('Pro LS Without Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/a0859989-fc61-41d8-81a6-7568cfd773cb', '_blank');
      }}>
        Without Files
      </Menu.Item>
    </Menu>
  );
  
  const menuAnalytics = (
    <Menu>
      <Menu.Item key="1" onClick={() => {
        // Track the click and open the URL for "with files"
        mixpanelTrack('Analytics LS With Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/2cdca14b-13df-40ea-ad9b-f7151738cfda', '_blank');
      }}>
        With Files
      </Menu.Item>
      <Menu.Item key="2" onClick={() => {
        // Track the click and open the URL for "without files"
        mixpanelTrack('Analytics LS Without Files Clicked');
        window.open('https://jorisrood.lemonsqueezy.com/buy/37bf2508-a6c3-435e-9e2a-f551b066019f', '_blank');
      }}>
        Without Files
      </Menu.Item>
    </Menu>
  );
  


  const showSubscriptionModal = () => {
    setIsSubscriptionModalVisible(true);
  };
  
  const handleSubscriptionModalOk = () => {
    // Handle the action to be taken when "OK" is clicked in the subscription modal
    setIsSubscriptionModalVisible(false);
  };
  
  const handleSubscriptionModalCancel = () => {
    setShowConfirmation(false);
    setSelectedTier(null);
    setIsSubscriptionModalVisible(false);
  };

  const handleConfirmChange = async () => {
    
    // Directly call handleChangeSubscription with both the new variant name and billing cycle
    await handleChangeSubscription(selectedTier, billingCycle); // Pass billingCycle if needed for the ID mapping
    setShowConfirmation(false); // Hide confirmation message after initiating the change
    // Optionally reset other states as needed
  };
  
  
  
  const handleChangeSubscription = async (newVariantName) => {
    // Ensure 'variantId' is correctly defined and accessible
    const currentBillingCycle = Object.values(variantMap).flatMap(tier => {
      if (tier.monthly === variantId.toString()) return 'monthly';
      if (tier.yearly === variantId.toString()) return 'yearly';
    }).find(cycle => cycle); // Correctly finds the first 'monthly' or 'yearly' match
  
    const direction = determineTierChangeDirection(productName, newVariantName);
    console.log("Current Billing Cycle:", currentBillingCycle); 
    const newVariantId = mapVariantNameAndBillingCycleToId(newVariantName, currentBillingCycle);
    console.log("New Variant ID:", newVariantId); // Debug log
    
    console.log(`Attempting to update subscription to ${newVariantName} (${currentBillingCycle} billing) with Variant ID: ${newVariantId}`);
    
    let requestBody = {
      subscriptionId: subscriptionId, // Make sure 'subscriptionId' is defined and accessible
      newVariantId: newVariantId,
      newVariantName: newVariantName,
      userId: user.id, // Assuming 'user.id' is accessible and correctly references the user's ID
      direction: direction === 'up' ? 'upgrade' : 'downgrade', // Add this line
      disableProrations: direction !== 'up'
    };
    
    if (direction === 'up') {
      console.log('This is an upgrade.');
      // Upgrade logic (no additional fields required here based on your description)
    } else {
      console.log('This is a downgrade.');
      // Include 'disable_prorations': true for downgrades
      requestBody["disable_prorations"] = true;
    }
  
    try {
      const response = await fetch('https://magicform.onrender.com/api/update-subscription', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    
      });
      
      if (!response.ok) {
        throw new Error(`Failed to update subscription: ${response.statusText}`);
      }
      
      const updatedSubscriptionData = await response.json();
      console.log('Subscription successfully updated:', updatedSubscriptionData);
      updateLicenseData({ productName: newVariantName });
      message.success('Product update was successful');
      // Update state/context as needed
    } catch (error) {
      console.error('Error updating subscription:', error);
    }
  };
  
  const showCancelModal = () => {
    setIsModalVisible(true);
  };
  
  

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await handleLicenseKeySubmit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setLicenseKey(value);

    // Check if the input has at least 10 characters
    if (value.length >= 10) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  async function handleLicenseKeySubmit() {
    
  
    try {
      console.log('user:', user.id);
      const response = await fetch('https://magicform.onrender.com/ls-endpoint', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          license_key: licenseKey,
          user_id: user.id,
        }),
      });
  
      const data = await response.json();
  
      if (data.error) {
        setErrorMessage('The license key is incorrect.'); // Set the error message
        message.error('Invalid license key'); // Show toast message for invalid license key
      } else {
        console.log('License key saved successfully');
        updateLicenseData();
        setIsModalVisible(false); // Close the modal
        // Notify success without a full page reload
        message.success('License key successfully updated');
      
      }
    } catch (error) {
      setErrorMessage('An error occurred.'); // Set a general error message
      message.error('An error occurred'); // Show toast message for general error
    }
  }





  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '100%', }}>
    <Navbar />
    <div style={{width: '100%', paddingBottom: 40, maxWidth: 1200, userSelect: 'none', paddingTop: 100, display: 'flex', flexDirection: 'row', justifyContent:'flex-start', gap: 8,  alignItems: 'center', }}>
        <p className="backToForms" onClick={() => navigate('/')}>Forms</p> <p className='sub' style={{paddingTop: 4}}> ﹥ </p> <p style={{fontWeight:'bold'}}>View plans</p>
        </div>
      

    <div className='pricing' style={{paddingBottom: '120px'}}>
      <div style={{marginBottom: '40px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
        <h1>Plans</h1>
        
        <p className='large' style={{paddingTop: 8}}>Experience the easiest and most capable form solution for Framer.</p>
      </div>

      <div className='current'>
        <div>
          {licenseStatus === "expired" 
          ? 
          <p className='bold' style={{color: 'red'}}>You're subscription has expired</p> 
          :
          licenseStatus === null ?
          <p className='bold'>You are on a free plan</p>
          :
          <p className='bold'>{productName}</p>
  }
          <p className='small'>{formsCount || 0} / {tierMax} forms  |  {submissionCount} submissions</p>
        </div>



        <Modal

          title="Change plan"
          open={isSubscriptionModalVisible}
          onOk={handleConfirmChange}
          onCancel={handleSubscriptionModalCancel}
          okText="Confirm"
          okButtonProps={{ disabled: !(showConfirmation && consentGiven) }}
          >
             <p className='sub' style={{paddingTop: 12, paddingBottom: 2}}>You are currently on the following plan:</p>
             <p>{productName}</p>
             <p className='sub' style={{paddingTop: 24, paddingBottom: 8}}>Want to upgrade or downgrade to a specific plan. Select the plan you'd like to change towards in the dropdown.</p>




             <Select
      style={{ width: '100%' }}
      value={selectedTier} // This assumes selectedTier is also using display names
      placeholder="Select a plan"
      onChange={(value) => {
        setSelectedTier(value);
        setShowConfirmation(true);
        const direction = determineTierChangeDirection(productName, value);
        setChangeDirection(direction);
      }}
    >
      {productOptions.map(option => (
        <Select.Option
          key={option}
          value={option}
          disabled={option === currentDisplayName} // Disable option if it matches the current product's display name
        >
          {option}
        </Select.Option>
      ))}
    </Select>

            {showConfirmation && (
  <div>
    {changeDirection === "up" ? (
      <>
        <p className='sub' style={{paddingTop: 12, paddingBottom: 2}}>You are about to change your subscription to:</p>
        <p>{selectedTier}</p>
        <p className='sub' style={{paddingTop: 24}}>Please note that when upgrading your plan, a prorated invoice will be created.</p>
      </>
    ) : (
      <>
        <p className='sub' style={{paddingTop: 12, paddingBottom: 2}}>You are about to change your subscription to:</p>
        <p>{selectedTier}</p>
        <p className='sub' style={{paddingTop: 24}}>Please note that when upgrading your plan, a prorated invoice will be created.</p>
      </>
    )}
       <Checkbox
        onChange={(e) => setConsentGiven(e.target.checked)}
        style={{ marginTop: '24px', marginBottom: 12 }}
      >
        I agree to the changes and accept the new plan.
      </Checkbox>
  </div>
)}
  

        </Modal>



          <div style={{display:'flex', gap: '8px'}}>
          <Button type ={licenseStatus != 'active' ? "primary" : "default"} onClick={showModal}>Activate license key</Button>
          {licenseStatus != null && <Button type ={licenseStatus != 'active' ? "default" : "primary"} onClick={showSubscriptionModal}>Change plan</Button>}
           
          
        </div>
        <Modal
        title="Activate License Key"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit license key"
        footer={[
          <Button key="cancel" onClick={handleCancel}>Cancel</Button>,
          <Button key="submit" type="primary" disabled={!isValid} onClick={handleOk}>Submit License Key</Button>
        ]}
      >
        <p className='small' style={{padding: '12px 0px'}}>You can find your License Key in the Order on LemonSqueezy or in the email receipt.</p>
        <Input
          type="text"
          placeholder="Enter your license key..."
          value={licenseKey}
          onChange={handleChange}
        />
         {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>} {/* Display error message */}
      </Modal>
      </div>
     

        
        <div className="container">
          
          
        <div className="column">
           
                <div className="priceSection">
                  <h3>Basic</h3>
                  <h2 style={{paddingTop: 4}}>€4,00</h2>
                  <p className='small' style={{fontSize: 12, paddingTop: 2}}>per month</p>
                </div>
                
                <div style={{paddingTop: 24, display: 'flex', gap: 8, flexDirection: 'column'}}>
                  <p>Manage 1 form</p>
                  <p>Unlimited submissions</p>
                </div>
                
              <div className="bottom">

                <div className="included">
                <img src="/checkp.png" />
                    <p className='small'>All integrations</p>
                  </div>

                  <div className="included">
                  <img src="/checkp.png" />
                    <p className='small'>Spam + bot protection</p>
                  </div>

                  <div className="included">
                  <img src="/checkp.png" />
                    <p className='small'>Email notifications</p>
                  </div>

                  <div className="included">
                  <img src="/checkp.png" />
                    <p className='small'>Confirmation emails</p>
                  </div>

                  <div className="included">
                    <img src="/checkp.png" />
                    <p className='small'>Export data</p>
                  </div>
                  
            </div>
            
            <div className="button-container">
  {licenseStatus === null ? (
    <Dropdown overlay={menuBasic} trigger={['click']}>
      <Button style={{ width: '100%' }}>
        Get: Basic
      </Button>
    </Dropdown>
  ) : (
    <Button style={{width: '100%'}} onClick={showSubscriptionModal}>Change plan</Button>
  )}
</div>
          
          </div>


       
       
       
       
          <div className="column">
           
           <div className="priceSection">
             <h3>Essentials</h3>
             <h2 style={{paddingTop: 4}}>€8,00</h2>
             <p className='small' style={{fontSize: 12, paddingTop: 2}}>per month</p>
           </div>
           
           <div style={{paddingTop: 24, display: 'flex', gap: 8, flexDirection: 'column'}}>
             <p>Manage 3 forms</p>
             <p>Unlimited submissions</p>
           </div>
           
     <div className="bottom">

        <div className="included">
        <img src="/checkp.png" />
           <p className='small'>All integrations</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Spam + bot protection</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Email notifications</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Confirmation emails</p>
         </div>

         <div className="included">
           <img src="/checkp.png" />
           <p className='small'>Export data</p>
         </div>
         
       </div>
       <div className="button-container">
  {licenseStatus === null ? (
    <Dropdown overlay={menuEssentials} trigger={['click']}>
      <Button style={{ width: '100%' }}>
        Get: Essentials
      </Button>
    </Dropdown>
  ) : (
    <Button style={{width: '100%'}} onClick={showSubscriptionModal}>Change plan</Button>
  )}
</div>

     </div>





     <div className="column">
           
           <div className="priceSection">
             <h3>Pro</h3>
             <h2 style={{paddingTop: 4}}>€16,00</h2>
             <p className='small' style={{fontSize: 12, paddingTop: 2}}>per month</p>
           </div>
           
           <div style={{paddingTop: 24, display: 'flex', gap: 8, flexDirection: 'column'}}>
             <p>Unlimited forms</p>
             <p>Unlimited submissions</p>
           </div>
           
     <div className="bottom">

        <div className="included">
        <img src="/checkp.png" />
           <p className='small'>All integrations</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Spam + bot protection</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Email notifications</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Confirmation emails</p>
         </div>

         <div className="included">
           <img src="/checkp.png" />
           <p className='small'>Export data</p>
         </div>
         
       </div>
       <div className="button-container">
  {licenseStatus === null ? (
    <Dropdown overlay={menuPro} trigger={['click']}>
      <Button type="primary" style={{ width: '100%' }}>
        Get: Pro
      </Button>
    </Dropdown>
  ) : (
    <Button style={{width: '100%'}} onClick={showSubscriptionModal}>Change plan</Button>
  )}
</div>
     </div>



     <div className="column">
           
           <div className="priceSection">
             <h3>Analytics</h3>
             <h2 style={{paddingTop: 4}}>€26,00</h2>
             <p className='small' style={{fontSize: 12, paddingTop: 2}}>per month</p>
           </div>
           
           <div style={{paddingTop: 24, display: 'flex', gap: 8, flexDirection: 'column'}}>
             <p>Unlimited forms</p>
             <p>Unlimited submissions</p>
           </div>
           
     <div className="bottom">

        <div className="included">
        <img src="/checkp.png" />
           <p className='small'>All from Pro plan</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Track UTM data</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Track Cookie data</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Track URLs</p>
         </div>

         
         
       </div>
       <div className="button-container">
  {licenseStatus === null ? (
    <Dropdown overlay={menuAnalytics} trigger={['click']}>
      <Button style={{ width: '100%' }}>
        Get: Analytics
      </Button>
    </Dropdown>
  ) : (
    <Button style={{width: '100%'}} onClick={showSubscriptionModal}>Change plan</Button>
  )}
</div>
     </div>



     <div className="column" style={{backgroundColor: '#0A0A0A'}}>
           
           <div className="priceSection" style={{backgroundColor: '#111111'}}>
             <h3>+Files addon</h3>
             <h2 style={{paddingTop: 4}}>€4,00</h2>
             <p className='small' style={{fontSize: 12, paddingTop: 2}}>per month</p>
           </div>
           
           <div style={{paddingTop: 24, display: 'flex', gap: 8, flexDirection: 'column'}}>
             <p>File uploads</p>
             <p>Available for all plans</p>
           </div>
           
     <div className="bottom">

        <div className="included">
        <img src="/checkp.png" />
           <p className='small'>Allow file uploads</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Unlimited storage</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Max. filesize 50mb</p>
         </div>

         <div className="included">
         <img src="/checkp.png" />
           <p className='small'>Max. 5 files per input</p>
         </div>

         
         
       </div>
      
     </div>



  



  









        
      
     </div>    
    </div>
    </div>
    


  );
};

export default Pricing;