import React, { useState, useEffect } from 'react';
import { Button, Spin } from 'antd';


const LoadingComponent = () => {
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    // Simulate loading state, ends in 3 seconds
    setTimeout(() => setLoading(false), 3000);

    // Show a message if loading takes more than 2 seconds
    const messageTimeoutId = setTimeout(() => {
      if (loading) {
        setShowMessage(true);
      }
    }, 2000);

    // Clear the timeout if the component unmounts
    return () => {
      clearTimeout(messageTimeoutId);
    };
  }, [loading]);

  return (
    <div className='colDir' style={{ alignItems: 'center', width: '100%', height: '100vh', gap: 40 }}>
      <Spin />
      {showMessage &&
      <div className='colDir' style={{gap: 8}}>
         <p>Loading is taking longer than usual.</p>
         <Button type="primary" style={{backgroundColor: '#2F00FF'}} onClick={() => window.location.reload()}>Refresh page</Button>
      </div>}
    </div>
  );
};

export default LoadingComponent;
