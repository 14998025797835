import React, { useState, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Input, message, Button } from 'antd';


function Template() {
    const location = useLocation();
    const formId = location.state?.formId; // Access formId from the location state
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/onboarding-end', { state: { formId } });
    };

    const handleClick = (url) => {
        console.log(url); // This will log the URL to the console
        window.open(url, '_blank');
      };
      
    

    const GridItem = ({ image, title, description, componentLink, previewLink }) => (
        <div className="grid-item">
          <img src={image} alt={title} />
          <div style={{padding: '24px 12px', display: 'flex', gap: 4, flexDirection:'column'}}>
          <p className="service" style={{fontWeight: 'bold'}}>{title}</p>
          <p className="sub small" style={{maxWidth: '100%', lineHeight: '20px'}}>{description}</p>
          <div className='rowDir' style={{marginTop: 12, gap: 4}}>
            
          <Button onClick={() => handleClick(componentLink)} style={{width: 'fit-content'}} size="small" type='primary' ghost>Remix component</Button>
            <Button onClick={() => handleClick(previewLink)} style={{width: 'fit-content'}} size="small">View preview</Button>

          </div>
          </div>
          
        </div>
      );

      const GridContainer = ({ items }) => (
        <div className="grid-container">
          {items.map(item => (
            <GridItem key={item.id} image={item.image} title={item.title} description={item.description} componentLink={item.componentLink} previewLink={item.previewLink} />

          ))}
        </div>
      );

      const gridItems = [
        { id: 1, image: '/og_template.png', title: 'MagicForm OG', componentLink: 'https://framer.com/projects/new?duplicate=kgsVp4gZYifUFf6FMnvP&via=jorisrood', previewLink: 'https://magicformcomponent.framer.website/', description: 'The original MagicForm component. Only some simple base styling and the usual fields added.' },
        { id: 2, image: '/email_template.png', title: 'Simple Email Component', componentLink: 'https://framer.com/projects/new?duplicate=Z5X5nlhKc7uW3JuZDyus&via=jorisrood', previewLink: 'https://decisive-tournaments-807944.framer.app/', description: "With this template we've already set everything up for the perfect email capture form." },
        // Add more items as needed
      ];
      

    const copyToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          message.success('Copied to clipboard!');
        } catch (err) {
          console.error('Failed to copy!', err);
          message.error('Failed to copy!');
        }
      };
      

  return (
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        
        flexWrap: 'wrap', // Allow the items to wrap to the next line on smaller screens
      }}>
        
      
     
    
       <div style={{
          flex: '1 0 auto', // Take up remaining space
          width: '100%',
          padding: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          boxSizing: 'border-box',
          '@media (max-width: 768px)': { // On small screens
            width: '100%', // Take up the full width
          },
      }}
    >
     
  
  
  
      <div className="signup" style={{width: '100%', maxWidth: '500px' }}>
    
        <div style={{ display: 'flex', gap: 4, flexDirection: 'column', alignItems: 'flex-start', marginBottom: 40 }}>
        <img src="/magicform_white.png"  alt="magicArm" style={{width: 100, marginBottom: 40}}/> 
        
      
        <h2>Get a template from our website</h2>
          <p className="sub">You can customize the form inside Framer completely to fit your brand and needs.</p>
        </div>
        
  
        
      <div className='colDir' style={{display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 8, justifyContent: 'flex-start',marginTop: 24}}>
      <Button type="primary" style={{width: 'fit-content'}}  onClick={() => window.open('https://getmagicform.com/templates', '_blank', 'noopener,noreferrer')}
>View templates</Button>
        <Button onClick={handleButtonClick}  style={{width: 'fit-content'}}>Next step</Button>
        
        </div>
</div>
  
  </div>
  
  </div>
  );
}

export default Template;
