import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, message, Button } from 'antd';
import supabase from '../supabaseClient';

function PasswordResetRequest() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const onSubmit = async (e) => {
  e.preventDefault();
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://app.getmagicform.com/password_reset',  // replace with your website URL
    });
    if (error) {
      message.error('An error occurred: ' + error.message);
    } else {
      message.success('Password reset email sent. Please check your inbox.');
      setTimeout(() => {
        navigate('/login');
      }, 2000);  // 2000 milliseconds = 2 seconds
    }
  } catch (error) {
    message.error('An error occurred: ' + error.message);
  }
};

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      flexWrap: 'wrap', // Allow the items to wrap to the next line on smaller screens
    }}>
    
   
  
     <div style={{
        flex: '1 0 auto', // Take up remaining space
        width: '100%',
        padding: '24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        boxSizing: 'border-box',
    }}
  >
   

    <div className="signup" style={{width: '100%', maxWidth: 500}}>
      <div style={{ display: 'flex', gap: 4, flexDirection: 'column', alignItems: 'flex-start', marginBottom: 40 }}>
      <img src="/magicform_white.png" className="logo" alt="Logo" />
        <h2>Request a new password</h2>
        <p className="sub">It happens to the best of us. Please enter your email below and request a password reset email.</p>
      </div>



    <form onSubmit={onSubmit}>
      <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
        <h4>Email</h4>
        <Input
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div style={{display: 'flex', flexDirection:'column', gap: 12}}>
      <Button style={{width: '100%'}} type="primary" htmlType="submit">
        Reset Password
      </Button>
      <Link className="link password" to="/">Go back</Link>
      </div>
    </form>
  
  </div>
</div></div>
  );
}
  
export default PasswordResetRequest;
