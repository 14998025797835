import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './UserContext';

// Function to dynamically load the Lemon Squeezy affiliate script
const loadAffiliateScript = () => {
  const script = document.createElement('script');
  script.innerHTML = `window.lemonSqueezyAffiliateConfig = { store: "jorisrood" };`;
  document.head.appendChild(script);

  const scriptSrc = document.createElement('script');
  scriptSrc.src = 'https://lmsqueezy.com/affiliate.js';
  scriptSrc.defer = true;
  document.head.appendChild(scriptSrc);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
);

loadAffiliateScript(); 

reportWebVitals();
