import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import { Input, message, Button } from 'antd';
import { mixpanelTrack } from '../mixpanelConfig'; 

function SignUpPage({session}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isSignUpSuccessful, setIsSignUpSuccessful] = useState(false);
  const navigate = useNavigate();
  

     useEffect(() => {
      mixpanelTrack('Sign Up Page Viewed');

    if (session) {
      // Redirect to '/'
      navigate(`/`);
    }
  }, [session]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
        message.error('Password and password confirmation do not match'); 
      return;
    }

    const { user, error } = await supabase.auth.signUp({
      
      email: email,
      password: password,
    });

    if (error) {
      console.error('Error signing up:', error);
      console.error('Detailed error:', JSON.stringify(error, null, 2));
      message.error(error.error_description || error.message);
    }
     else {
      console.log('Success! Check your email for a confirmation link.');
      mixpanelTrack('Sign Up Successful');
      setIsSignUpSuccessful(true);
      
    }
  };

  return (
  <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    
    flexWrap: 'wrap', // Allow the items to wrap to the next line on smaller screens
  }}>
  
 

  <div className="signupInput" style={{ 
      padding: '24px',  
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      boxSizing: 'border-box',
     
    }}>
    {!isSignUpSuccessful ? 
        <div className="signup" style={{width: '100%', maxWidth: 500}}>
      <div style={{ display: 'flex', gap: 4, flexDirection: 'column', alignItems: 'flex-start', marginBottom: 40 }}>
      <img src="/magicform_white.png" className="logo" alt="Logo" />
        <h2>May the forms be with you.</h2>
        <p className="sub">Start receiving form submissions for <br/>your Framer site in seconds.</p>
      </div>
      
      
    

  
      <form onSubmit={handleSignUp}>
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
          <h4>Email</h4>
          <Input
            type="email"
            placeholder="joris@getmagicform.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
          <h4>Password</h4>
          <Input.Password
            placeholder="Enter a password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div style={{ display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 24 }}>
          <h4>Confirm Password</h4>
          <Input.Password
            placeholder="Confirm your password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
        </div>
        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
          Get started
        </Button>
      </form>


    <div
      style={{
        marginTop: 40,
        display: 'flex',
        gap: 6,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <p>Already have an account?</p>
      <Link className="link" to="/login">
        Go to Login
      </Link>
    </div>
  </div>
  

  :
  <div style={{ display: 'flex', gap: 8, flexDirection: 'column'}}>
    <p>Success! Check your email for a confirmation link.</p>
    <Link className="link" to="/login">Back to Login</Link>
  </div>
  }
  </div>


  
</div>

  );
}

export default SignUpPage;
