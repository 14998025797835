import React, { useEffect, useState, useContext,  } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import supabase from './supabaseClient';
import { ConfigProvider, theme, Spin } from 'antd';
import Login from './pages/Login';
import PasswordReset from './pages/password_reset';
import PasswordResetRequest from './pages/password_request';
import SignUpPage from './pages/SignUpPage';
import Forms from './pages/Forms';
import Setup from './pages/setup';
import Onboarding from './pages/onboarding';
import Template from './pages/get-template';
import EndOnboarding from './pages/onboarding-end';
import Pricing from './pages/pricing';
import Profile from './pages/profile';
import FormDetail from './pages/FormDetail';
import FormIntegrations from './pages/FormIntegrations';
import FormSettings from './pages/FormSettings';
import DelayedRedirect from './Delayed';
import { UserProvider } from './UserContext';
import LoadingComponent from './components/LoadingComponent';
import { LicenseProvider, LicenseContext } from './LicenseContext'; 
import { mixpanelTrack } from './mixpanelConfig'; 





function App() {
  const [loading, setLoading] = useState(true);
  const [session, setSession] = useState(null);

  function handleVisibilityChange() {
    if (!document.hidden) {
        // Tab is active again.
        
       
        

        supabase.auth.getSession()
        .then(({ data: { session } }) => {
          
            setSession(session);
        })
        .catch(error => {
            console.log('Error fetching session:', error);
        });
      
    }
}


const MixpanelTracker = () => {
  const location = useLocation(); // This hook gives you the current location object

  useEffect(() => {
    // Track page view with Mixpanel
    // You might want to customize what exactly gets sent in the event.
    mixpanelTrack('Page View', {
      path: location.pathname
    });
  }, [location]); // This effect will re-run every time the location changes

  return null; // This component doesn't render anything
};



useEffect(() => {
  // Fetch session on component mount and set up auth state change listener
  const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
    setSession(session);
    setLoading(false);
  });

  // Fetch the initial session
  supabase.auth.getSession().then(({ data: { session } }) => {
    setSession(session);
  });

  return () => {
    subscription.unsubscribe();
  };
}, []);

useEffect(() => {
  // Set up the event listener for visibility change
  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Clean up the event listener
  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, []); // Empty dependency array to run only on mount and unmount

// ... rest of your component


  if (loading) {  
    return <div><LoadingComponent /></div>;
  }

  return (
    <LicenseProvider>
       <ConfigProvider 
      theme={{
        token: {
          colorBgBase: "#111111",
          colorError: "#FF0020",
          colorInfo: "#f9f9f9",
          colorLink: "#ff5f00",
          colorSuccess: "#2BAD7C",
          colorTextBase: "#f9f9f9",
          colorWarning: "f9f9f9",
          controlHeight: 40,
          borderRadius: 8,
          colorPrimary: '#ff5f00',
          borderPrimary: '#565659',
          
        },
      }}>
        <UserProvider>
          <Router>
          <MixpanelTracker />
          <AppRoutes session={session} />
          </Router>
        </UserProvider>
      </ConfigProvider>
    </LicenseProvider>
  );
}

function AppRoutes({ session }) {
  const { licenseStatus, licenseLoading, formsCount, hasFinishedOnboarding  } = useContext(LicenseContext);
  const [isSetupComplete, setIsSetupComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation(); // Get the current location
  // Inside your component


  
  useEffect(() => {
    const noLoadingPaths = ['/login', '/signup', '/password_request', '/password_reset'];
  
    if (!session || noLoadingPaths.includes(location.pathname)) {
      setIsLoading(false); // Set loading false if no session or current path is in noLoadingPaths
    } else {
      setIsLoading(true); // Set loading true otherwise
      const fetchUserProfile = async () => {
        // Fetch user profile logic
        const { data, error } = await supabase
          .from('users')
          .select('first_name') // or any other field that indicates completion of setup
          .eq('id', session.user.id)
          .single();
  
        if (error) {
          console.error('Error fetching profile:', error);
        } else {
          setIsSetupComplete(!!data && !!data.first_name); // Check if the first name is set
        }
        setIsLoading(false); // Ensure loading is set to false after fetching
      };
  
      fetchUserProfile();
    }
  }, [ location.pathname ]);
  
  if (isLoading) {
    return <LoadingComponent />; // Replace with your loading component
  }

  if (licenseLoading) {
    return <LoadingComponent />;
  }



  const renderProtectedRoute = (Component, redirectPath = "/login") => {
    return session ? <Component /> : <Navigate to={redirectPath} />;
  };


  return (
    
      <Routes>
          
            <Route path="/login"  element={session ? <Navigate to="/" /> : <Login />} />
            <Route path="/signup" element={<SignUpPage session={session} />} />
            <Route path="/password_request" element={<PasswordResetRequest />} />
            <Route path="/password_reset" element={<PasswordReset />} />
            <Route path="/setup" element={session ? <Setup /> : <Navigate to="/login" />} />
            <Route path="/profile" element={session ? <Profile /> : <Navigate to="/login" />} />
            <Route path="/onboarding" element={session ? (hasFinishedOnboarding ? <Navigate to="/" /> : <Onboarding />) : <Navigate to="/login"/>} />
<Route path="/get-template" element={session ? (hasFinishedOnboarding ? <Navigate to="/" /> : <Template />) : <Navigate to="/login"/>} />
<Route path="/onboarding-end" element={session ? (hasFinishedOnboarding ? <Navigate to="/" /> : <EndOnboarding />) : <Navigate to="/login"/>} />

            <Route 
  path="/" 
  element={
    (() => {
      if (session) {
        if (!isSetupComplete) {
          return <Navigate to="/setup" />;
        } else if (!hasFinishedOnboarding && formsCount === 0) {
          return <Navigate to="/onboarding" />;
        } else if (licenseStatus === 'active' || licenseStatus === null) {
          return <Forms />;
        } else {
          return <DelayedRedirect to="/pricing" />;
        }
      } else {
        return <Navigate to="/login" />;
      }
    })()
  }
/>





            
            <Route path="/forms/:formId" element={
    (() => {
      if (session) {
        if (licenseStatus === 'active' || licenseStatus === null ) {
          return <FormDetail />;
        } else {
          return <DelayedRedirect to="/pricing" delay={1000} />;
        }
      } else {
        return <Navigate to="/login" />;
      }
    })()
  }
/>
            <Route path="/forms/:formId/integrations" element={
    (() => {
      if (session) {
          return <FormIntegrations />;
      } else {
        return <Navigate to="/login" />;
      }
    })()
  }
/>
            <Route path="/forms/:formId/settings"element={
    (() => {
      if (session) {
          return <FormSettings />;
      } else {
        return <Navigate to="/login" />;
      }
    })()
  }
/>
<Route path="/pricing" element={session ? <Pricing /> : <Navigate to="/login" />} />
            
          </Routes>
        
  );
}

export default App;
