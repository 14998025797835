import { React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const DelayedRedirect = ({ to, delay }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(to);
    }, delay);

    return () => clearTimeout(timer);
  }, [to, delay, navigate]);

  return <div>Redirecting...</div>;
};

export default DelayedRedirect;
